import { useLogout } from '@hooks/useLogout.tsx';
import { useMutation } from '@tanstack/react-query';

import { queryKeyTypes } from '@/api/queryKeyTypes';
import { useApi } from '@/api/serviceProvider';
import { useAuthEmailStore } from '@/stores/authEmailStore';
import { showToast } from '@/utils/showToast';

export const useConfirmPasswordReset = () => {
  const apiFactory = useApi();
  const { handleLogout } = useLogout();
  const clearInitialEmail = useAuthEmailStore((state) => state.clearEmail);

  const { mutate, isPending } = useMutation({
    mutationKey: [queryKeyTypes.resetPasswordConfirm],
    mutationFn: async ({
      password,
      confirmPassword,
      uid,
      token,
    }: {
      password: string;
      confirmPassword: string;
      uid: string;
      token: string;
    }) => {
      if (!uid || !token || !password || !confirmPassword) {
        return;
      }

      return await apiFactory.auth.authPasswordResetConfirmCreate({
        new_password1: password,
        new_password2: confirmPassword,
        uid,
        token,
      });
    },
    onSuccess: () => {
      clearInitialEmail();
      showToast('Password has been reset successfully');
      handleLogout();
    },
    onError: () => showToast("We're sorry, we could not set a new password."),
  });

  return {
    handleConfirmPasswordReset: mutate,
    isConfirmPasswordResetLoading: isPending,
  };
};
