import { create } from 'zustand';
import { Budget, Tailoring, TailoringAccommodation } from '@/generated';
import { FoodPreferenceType } from '@/types/FoodPreference';
import { ActivityTag } from '@/types/AppTypes';

export type InvitationSearchTypes = {
  token?: string;
  username?: string;
  companions?: Tailoring['companions'];
  interests?: ActivityTag[];
  foodPreferences?: FoodPreferenceType[];
  diets?: FoodPreferenceType[];
  earliestStart?: string;
  dailyBudget?: Budget;
  accommodation?: TailoringAccommodation;
  specificThingsToInclude?: string;
};

type State = {
  invitationSearch: InvitationSearchTypes;
};

type Actions = {
  setInvitationSearch: (value: Partial<InvitationSearchTypes>) => void;
  resetInvitationSearch: () => void;
};

const initialState: InvitationSearchTypes = {
  companions: Tailoring['companions']['NO_ONE'],
};

const getInitialState = (): InvitationSearchTypes => {
  const storedState = localStorage.getItem('invitationSearch');
  return storedState ? JSON.parse(storedState) : initialState;
};

export const useInvitationStore = create<State & Actions>((set) => ({
  invitationSearch: getInitialState(),
  setInvitationSearch: (value) => {
    set((state) => {
      const newState = {
        ...state.invitationSearch,
        ...value,
      };
      localStorage.setItem('invitationSearch', JSON.stringify(newState));
      return { invitationSearch: newState };
    });
  },
  resetInvitationSearch: () => {
    set((state) => {
      const token = state.invitationSearch.token;
      const newState = { ...initialState, token };
      localStorage.setItem('invitationSearch', JSON.stringify(newState));
      return { invitationSearch: newState };
    });
  },
}));
