import classNames from 'classnames';
import { ItineraryItemCard } from '@components/cards/ItineraryItemCard';
import { Experience } from '@/generated';
import { CardOnMap } from '../cards/CardOnMap';
import { forwardRef } from 'react';

type Props = {
  selectedDate: string | null | undefined;
  selectedExperienceId: number | null | undefined;
  experiences: Experience[];
  chosenExperienceRef: React.MutableRefObject<{
    [key: string]: HTMLDivElement | null;
  }>;
  handleOnScroll: () => void;
  selectedExperience: Experience | undefined;
};

export const BottomBar = forwardRef<HTMLDivElement, Props>(
  (
    {
      selectedDate,
      selectedExperienceId,
      experiences,
      chosenExperienceRef,
      handleOnScroll,
      selectedExperience,
    },
    ref,
  ) => {
    return (
      <div
        className={classNames('fixed bottom-0 z-10 w-full', {
          'px-2 pb-4': !selectedDate && selectedExperienceId,
        })}
      >
        {selectedDate && (
          <div
            ref={ref}
            className="overflow-x-auto flex pb-4 scrollbar-hide touch-pan-x cursor-grab snap-x scroll-smooth lg:hidden"
            onScroll={handleOnScroll}
          >
            {experiences?.map(
              (experience) =>
                experience.id && (
                  <ItineraryItemCard
                    key={experience.id}
                    experience={experience}
                    ref={(el) => {
                      if (el && chosenExperienceRef?.current && experience.id) {
                        chosenExperienceRef.current[experience.id] = el;
                      }
                    }}
                  />
                ),
            )}
          </div>
        )}
        {!selectedDate && selectedExperienceId && (
          <CardOnMap
            name={selectedExperience?.name}
            details={selectedExperience?.details}
            styles="lg:hidden"
          />
        )}
      </div>
    );
  },
);
