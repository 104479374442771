import React from "react";

export const useScrollToTheTop = () => {
    const itemRef = React.useRef<HTMLDivElement>(null);

    const scrollToTheTop = () => {
        itemRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return { scrollToItemRef: itemRef, scrollToTheTop };

};