import {
  DEFAULT_BLOG_PAGE_CANONICAL_URL,
  DEFAULT_BLOG_PAGE_DESCRIPTION,
  DEFAULT_BLOG_PAGE_KEYWORDS,
  DEFAULT_BLOG_PAGE_OG_IMAGE,
  DEFAULT_BLOG_PAGE_TITLE,
} from '@/utils/constants';
import { Helmet } from 'react-helmet';

export const BlogPageHelmet = () => {
  return (
    <Helmet>
      <title>{DEFAULT_BLOG_PAGE_TITLE}</title>
      <meta name="description" content={DEFAULT_BLOG_PAGE_DESCRIPTION} />
      <meta name="keywords" content={DEFAULT_BLOG_PAGE_KEYWORDS} />
      <meta property="og:title" content={DEFAULT_BLOG_PAGE_TITLE} />
      <meta property="og:description" content={DEFAULT_BLOG_PAGE_DESCRIPTION} />
      <meta property="og:image" content={DEFAULT_BLOG_PAGE_OG_IMAGE} />
      <meta property="og:url" content={DEFAULT_BLOG_PAGE_CANONICAL_URL} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={DEFAULT_BLOG_PAGE_TITLE} />
      <meta
        name="twitter:description"
        content={DEFAULT_BLOG_PAGE_DESCRIPTION}
      />
      <meta name="twitter:image" content={DEFAULT_BLOG_PAGE_OG_IMAGE} />
      <link rel="canonical" href={DEFAULT_BLOG_PAGE_CANONICAL_URL} />
    </Helmet>
  );
};
