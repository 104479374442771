import { useNavigate } from "react-router-dom";

import { BlogCategory } from "@/generated";

import { routes } from "@/routes/routes";

import { TopicType, useBlogStore } from "@/stores/blogStore";

type Props = {
    onCategoryClickAdditionalMethod?: () => void;
    onArticleClickAdditionalMethod?: () => void;
    onViewMoreAdditionalMethod?: () => void;
}

export const useBlogButtonHandlers = ({
    onCategoryClickAdditionalMethod,
    onArticleClickAdditionalMethod,
    onViewMoreAdditionalMethod,
}: Props) => {
    const navigate = useNavigate();
    const { activeTopic, setActiveCategory, setActiveTopic } = useBlogStore();

    const handleOnCategoryClick = (category: BlogCategory) => {
        setActiveCategory(category);
        navigate(
            routes.blog.topic
                .replace(':topic', activeTopic.slug)
                .replace(':category', category.slug!!),
            { replace: true },
        );
        onCategoryClickAdditionalMethod ? onCategoryClickAdditionalMethod() : null;
    };

    const handleOnArticleClick = (articleSlug: string) => {
        navigate(routes.blog.article.replace(':slug', articleSlug), {
            state: { from: '/' },
        });
        onArticleClickAdditionalMethod ? onArticleClickAdditionalMethod() : null;
    };

    const handleOnViewMore = (topic: TopicType) => {
        setActiveTopic(topic);
        navigate(
            routes.blog.topic
                .replace(':topic', topic.slug)
                .replace(':category', 'all-categories'),
        );
        onViewMoreAdditionalMethod ? onViewMoreAdditionalMethod() : null;
    };


    return {
        handleOnCategoryClick,
        handleOnArticleClick,
        handleOnViewMore,
    }
};