import { queryKeyTypes } from '@/api/queryKeyTypes';
import { useApi } from '@/api/serviceProvider';
import { DEFAULT_BLOG_CATEGORY } from '@/utils/constants';
import { useQuery } from '@tanstack/react-query';

export const useGetCategories = (disabled: boolean = false) => {
    const { api } = useApi();
    const { data, isLoading, error } = useQuery({
        queryKey: [queryKeyTypes.blog, queryKeyTypes.blog_categories],
        queryFn: async () => {
            return await api.apiV1BlogCategoriesList();
        },
        retry: 1,
        enabled: !disabled,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 30,  // 30 minutes
    })

    return {
        categories: data?.results ? [DEFAULT_BLOG_CATEGORY, ...data.results] : [DEFAULT_BLOG_CATEGORY],
        isCategoriesLoading: isLoading,
        categoriesError: error,
    }
}