import { BannerTailor } from '@components/banners/BannerTailor';
import { BottomBar } from '@components/bottomBars/BottomBar';
import { ExperienceChangeDateTimeBottomSheet } from '@components/bottomSheets/ExperienceChangeDateTimeBottomSheet';
import { ExperienceMapBottomSheet } from '@components/bottomSheets/ExperienceMapBottomSheet';
import { DayButton } from '@components/buttons/DayButton';
import { UpperPart } from '@components/headers/UpperPart';
import { ItineraryExperienceList } from '@components/lists/ItineraryExperienceList';
import { ItineraryLoader } from '@components/loaders/ItineraryLoader';
import { ChangeCommuteOptionsModal } from '@components/modals/ChangeCommuteOptionsModal';
import { ChangeExperienceOptionsModal } from '@components/modals/ChangeExperienceOptionsModal';
import { EditTripWindow } from '@components/modals/EditTripWindow';
import { TailoringModal } from '@components/modals/TailoringModal';
import { ItineraryTooltips } from '@components/tooltips/ItineraryTooltips';
import { useGatherExperiencesDetails } from '@hooks/useGatherExperiencesDetails';
import { useGenerateItinerary } from '@hooks/useGenerateItinerary';
import { useGetItineraryCommutes } from '@hooks/useGetItineraryCommutes';
import { useGetUniqueDateRangeFromExperiences } from '@hooks/useGetUniqueDateRangeFromExperiences';
import { useInitialSearchStore } from '@stores/initialSearchStore';
import { useIsMutating } from '@tanstack/react-query';
import {
  getExperiencesBySelectedDate,
  getMergedExperiences,
} from '@utils/experiencesHelperFunctions';
import { getTotalPeopleCount } from '@utils/getTotalPeopleCount';
import { getUniqueDatesFromExperiences } from '@utils/getUniqueArray';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { DeleteItineraryBottomSheet } from '@/components/bottomSheets/DeleteItineraryBottomSheet';
import { EditPreferencesTooltip } from '@/components/tooltips/EditPreferencesTooltip';
import { Commute, Experience } from '@/generated';
import { useRedirectOnInvalidItineraryId } from '@/hooks/useRedirectOnInvalidItineraryId';
import { useShowItineraryTooltips } from '@/hooks/useShowItineraryTooltips';
import { useShowTailoringTooltips } from '@/hooks/useShowTailoringTooltips';
import { DEV_ENV_URL, YEAR_MONTH_DAY_FORMAT } from '@/utils/constants';
import { getFormattedDate, parseDate } from '@/utils/formatDateTime';
import { SpriteIcon } from '@/components/icons/SpriteIcon';
import { ExperienceMap } from '@/components/map/ExperienceMap';
import { FilterItemButton } from '@/components/buttons/FilterItemButton';
import { routes } from '@/routes/routes';
import { useItineraryStore } from '@/stores/itineraryStore';
import { useGoogleAnalytics } from '@/hooks/useGoogleAnalytics';
import { ActivityTag, ItineraryWithExperiences } from '@/types/AppTypes';
import { ItineraryHelmet } from '@/components/helmets/ItineraryHelments';

type Params = {
  itineraryId: number | undefined;
  isItineraryLoading?: boolean;
  itinerary: ItineraryWithExperiences;
  isAdmin: boolean;
};

export const ItineraryPage = ({
  itineraryId,
  isItineraryLoading,
  itinerary,
  isAdmin,
}: Params) => {
  const [selectedDate, setSelectedDate] = useState<string | null | undefined>(
    getFormattedDate({
      date: useInitialSearchStore((state: any) => state.dateRange?.from),
      format: YEAR_MONTH_DAY_FORMAT,
    }),
  );

  const [isExperienceChangeDateTimeOpen, setIsExperienceChangeDateTimeOpen] =
    useState(false);
  const [experienceToEdit, setExperienceToEdit] = useState<Experience | null>(
    null,
  );
  const [commuteToEdit, setCommuteToEdit] = useState<Commute | null>(null);
  const [isChangeExperienceModalOpen, setIsChangeExperienceModalOpen] =
    useState(false);
  const [isChangeCommuteModalOpen, setIsChangeCommuteModalOpen] =
    useState(false);
  const [isEditTripSheetOpen, setIsEditTripSheetOpen] = useState(false);
  const [isTailoringModalOpen, setIsTailoringModalOpen] = useState(false);
  const [isExperienceMapSheetOpen, setIsExperienceMapSheetOpen] =
    useState(false);
  const [
    isDeleteItineraryBottomSheetOpen,
    setIsDeleteItineraryBottomSheetOpen,
  ] = useState(false);

  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollableElementRef = useRef<HTMLDivElement>(null);

  const { id } = useParams();
  useRedirectOnInvalidItineraryId(id);

  const location = useLocation();
  const nav = useNavigate();

  const { didShowItineraryTooltips, handleCloseItineraryTooltip } =
    useShowItineraryTooltips();

  const { didShowTailoringTooltips, handleCloseTailoringTooltip } =
    useShowTailoringTooltips();

  const initialSearchDateRange = useInitialSearchStore(
    (state) => state.dateRange,
  );

  const setItineraryPage = useItineraryStore(
    (state) => state.setItinerarySearch,
  );

  const { isPending: isGenerateItineraryPending, generateItinerary } =
    useGenerateItinerary({});

  const { experienceDetails, isExperienceDetailsLoading } =
    useGatherExperiencesDetails(itinerary?.experiences ?? []);

  const experiences = getMergedExperiences({
    experiences: itinerary?.experiences,
    experienceDetails,
    isDevEnv: location.pathname.includes(DEV_ENV_URL),
  });

  const selectedExperiences = getExperiencesBySelectedDate(
    experiences,
    selectedDate,
  );

  const { commutes, allCommutes, isCommutesLoading, refetchForDate } =
    useGetItineraryCommutes(
      itinerary?.id,
      experiences,
      isExperienceDetailsLoading,
      selectedDate,
    );

  const sortedExperiencesDates = getUniqueDatesFromExperiences(
    experiences ?? [],
  );
  useGetUniqueDateRangeFromExperiences(experiences, (val: string[]) =>
    setItineraryPage({ itineraryDaysRange: val }),
  );

  const { sendGAEvent, EVENT_ACTIONS, EVENT_CATEGORIES } = useGoogleAnalytics();

  const isChangeExperienceLoading =
    useIsMutating({
      mutationKey: ['suggestDifferentExperience'],
      exact: true,
    }) > 0;
  const isUpdateExperienceLoading =
    useIsMutating({ mutationKey: ['updateExperience'], exact: true }) > 0;

  const isLoading = [
    isExperienceDetailsLoading,
    isChangeExperienceLoading,
    isUpdateExperienceLoading,
    isCommutesLoading,
    isGenerateItineraryPending,
    isItineraryLoading,
  ].some((loading) => loading);

  useEffect(() => {
    handleSelectDate(
      getFormattedDate({
        date: initialSearchDateRange?.from,
        format: YEAR_MONTH_DAY_FORMAT,
      }),
    );
  }, [initialSearchDateRange]);

  useEffect(() => {
    setSelectedDate(sortedExperiencesDates[0]);
    setItineraryPage({ itineraryDestination: itinerary.city || '' });
  }, []);

  const handleSelectDate = (date: string) => {
    setSelectedDate(date);
    scrollableElementRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleExperienceEditCardClick = (experience: Experience) => {
    setExperienceToEdit(experience);
    toggleModalState(setIsChangeExperienceModalOpen);
  };

  const handleCommuteEditClick = (commute: Commute) => {
    setCommuteToEdit(commute);
    toggleModalState(setIsChangeCommuteModalOpen);
  };

  const handleToggleIsChangeExperienceDateModalOpen = () =>
    toggleModalState(setIsExperienceChangeDateTimeOpen);

  const handleToggleEditTripSheetOpen = () =>
    toggleModalState(setIsEditTripSheetOpen);

  const handleToggleTailoringModalOpen = () => {
    if (!isTailoringModalOpen) {
      /* How many users click on “Tailor itinerary” */
      sendGAEvent(EVENT_CATEGORIES.itinerary, EVENT_ACTIONS.tailorItinerary);
    }
    toggleModalState(setIsTailoringModalOpen);
  };

  const handleToggleExperienceMapSheetOpen = () =>
    toggleModalState(setIsExperienceMapSheetOpen);

  const handleToggleChangeExperienceOptionsModal = () =>
    toggleModalState(setIsChangeExperienceModalOpen);

  const handleToggleChangeCommuteOptionsModal = () =>
    toggleModalState(setIsChangeCommuteModalOpen);

  const onEditSaveClick = () => {
    generateItinerary();
    handleToggleEditTripSheetOpen();
  };

  const editTripCallerRef = useRef<HTMLButtonElement | null>(null);
  const editExperienceCallerRef = useRef<{
    [key: string]: HTMLButtonElement | null;
  }>({});
  const editItineraryDivRef = useRef<HTMLDivElement | null>(null);
  const timeSlotElementRef = useRef<HTMLDivElement | null>(null);

  const getExperienceRef = (
    experienceId: number | undefined,
  ): HTMLButtonElement | null => {
    if (!experienceId) return null;
    return editExperienceCallerRef.current[experienceId] || null;
  };

  if (isGenerateItineraryPending) {
    return <ItineraryLoader dateRange={initialSearchDateRange} />;
  }

  return (
    <>
      <ItineraryHelmet itinerary={itinerary} />
      <main className="w-full h-svh mx-auto relative bg-white flex flex-col items-center justify-center">
        {itinerary?.id && (
          <div className="w-full h-full relative bg-gray-light lg:grid md:grid-cols-12 md:overflow-hidden">
            <div className="w-full h-full lg:h-svh relative flex flex-col bg-white md:col-span-6 md:overflow-auto lg:col-span-4">
              <div className="flex flex-col items-center sticky top-0 gap-y-4 lg:gap-y-0 bg-white shadow-header pb-4 z-10 pt-2">
                <div className="hidden mdx:flex w-full my-[22px]">
                  <SpriteIcon
                    className="w-[114px] h-4 mx-[20px] cursor-pointer"
                    iconName="logoMobile"
                    onClick={() => nav(routes.discovery.index)}
                  />
                </div>
                <UpperPart
                  location={itinerary.city ?? 'Unknown location'}
                  dateRangeText={
                    parseDate({
                      startDate: itinerary.start_date,
                      endDate: itinerary.end_date,
                    }) ?? 'Invalid date range'
                  }
                  horizontalSpacing={2}
                  people={getTotalPeopleCount(itinerary.persons) ?? 0}
                  isLoadingData={isLoading}
                  onEditClick={handleToggleEditTripSheetOpen}
                  onMapClick={handleToggleExperienceMapSheetOpen}
                  is_admin={isAdmin}
                  ref={editTripCallerRef}
                  editItineraryDivRef={editItineraryDivRef}
                />
                <div className="w-full overflow-x-auto flex px-2 gap-x-2 scrollbar-hide mx-auto lg:mt-4">
                  <FilterItemButton
                    onClick={() => setSelectedDate(null)}
                    selected={!selectedDate}
                  >
                    All days
                  </FilterItemButton>
                  {sortedExperiencesDates.map(
                    (date, index) =>
                      date && (
                        <DayButton
                          key={index}
                          onClick={() => handleSelectDate(date)}
                          selected={selectedDate === date}
                          date={date}
                        />
                      ),
                  )}
                </div>
              </div>

              <div
                ref={scrollRef}
                className="flex flex-col items-center p-2 w-full gap-y-6 bg-gray-light h-full overflow-auto"
              >
                {isAdmin && (
                  <BannerTailor
                    title="Tailor your experience"
                    text="Tell us more about you and your trip to make your itinerary even better"
                    buttonText="Get started"
                    onClick={handleToggleTailoringModalOpen}
                    ref={scrollableElementRef}
                  />
                )}
                <ItineraryExperienceList
                  experiences={selectedExperiences ?? []}
                  commutes={selectedDate ? commutes : allCommutes}
                  fetchCommutesForDate={refetchForDate}
                  isExperienceDetailsLoading={isExperienceDetailsLoading}
                  isLoadingCommutes={isCommutesLoading}
                  isLoadingList={isChangeExperienceLoading}
                  onCardEditClick={handleExperienceEditCardClick}
                  onCommuteEditClick={handleCommuteEditClick}
                  ref={null}
                  menuCallerRef={editExperienceCallerRef}
                  firstExperienceTimeSlotRef={timeSlotElementRef}
                  isAdmin={isAdmin}
                  isDaySelected={!!selectedDate}
                />
              </div>
              {isAdmin &&
                !isAnyModalOpen([
                  isChangeCommuteModalOpen,
                  isEditTripSheetOpen,
                  isTailoringModalOpen,
                  isExperienceMapSheetOpen,
                ]) && <BottomBar itineraryId={itineraryId} />}
              {!didShowTailoringTooltips &&
                !isAnyModalOpen([
                  isChangeCommuteModalOpen,
                  isEditTripSheetOpen,
                  isTailoringModalOpen,
                  isExperienceMapSheetOpen,
                ]) && (
                  <EditPreferencesTooltip
                    location={itinerary.city ?? 'Unknown location'}
                    people={getTotalPeopleCount(itinerary.persons) ?? 0}
                    editItineraryDivRef={editItineraryDivRef.current}
                    dateRangeText={
                      parseDate({
                        startDate: itinerary.start_date,
                        endDate: itinerary.end_date,
                      }) ?? 'Invalid date range'
                    }
                    onClick={handleCloseTailoringTooltip}
                  />
                )}
              {!didShowItineraryTooltips &&
                didShowTailoringTooltips &&
                !isAnyModalOpen([
                  isChangeCommuteModalOpen,
                  isEditTripSheetOpen,
                  isTailoringModalOpen,
                  isExperienceMapSheetOpen,
                ]) && (
                  <ItineraryTooltips
                    startTime={
                      selectedExperiences && selectedExperiences[0]?.start_time
                    }
                    endTime={
                      selectedExperiences && selectedExperiences[0]?.end_time
                    }
                    onClose={handleCloseItineraryTooltip}
                    date={selectedExperiences && selectedExperiences[0]?.date}
                    timeSlotElement={timeSlotElementRef.current}
                  />
                )}
            </div>
            <div className="hidden lg:flex w-full h-full col-span-8">
              <ExperienceMap
                experiences={selectedExperiences ?? []}
                commutes={commutes}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                rounded={false}
              />
            </div>
          </div>
        )}
        {isDeleteItineraryBottomSheetOpen && (
          <DeleteItineraryBottomSheet
            itineraryId={itineraryId}
            isOpen={isDeleteItineraryBottomSheetOpen}
            onClose={() => setIsDeleteItineraryBottomSheetOpen(false)}
          />
        )}
        {experienceToEdit && (
          <ExperienceChangeDateTimeBottomSheet
            key={experienceToEdit.id}
            experience={experienceToEdit}
            isOpen={isExperienceChangeDateTimeOpen}
            onClose={handleToggleIsChangeExperienceDateModalOpen}
          />
        )}
        <EditTripWindow
          isOpen={isEditTripSheetOpen}
          onClose={handleToggleEditTripSheetOpen}
          onSaveClick={onEditSaveClick}
          onDeleteTripClick={() => setIsDeleteItineraryBottomSheetOpen(true)}
          itineraryData={itinerary}
          menuCaller={editTripCallerRef.current}
          onEditTripInfoClick={() => {
            () => toggleModalState(setIsEditTripSheetOpen);
          }}
          onEditPreferencesClick={handleToggleTailoringModalOpen}
          isAdmin={isAdmin}
        />
        <ExperienceMapBottomSheet
          dateExperiences={experiences}
          commutes={commutes}
          isOpen={isExperienceMapSheetOpen}
          onClose={handleToggleExperienceMapSheetOpen}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
        {isTailoringModalOpen && itinerary?.id && (
          <TailoringModal
            itineraryId={itinerary.id}
            onClose={handleToggleTailoringModalOpen}
            activity_tags={
              itinerary.destination?.activity_tags as ActivityTag[]
            }
          />
        )}
        {experienceToEdit && (
          <ChangeExperienceOptionsModal
            experience={experienceToEdit}
            onChangeDateTimeClick={handleToggleIsChangeExperienceDateModalOpen}
            isOpen={isChangeExperienceModalOpen}
            onClose={handleToggleChangeExperienceOptionsModal}
            onOverlayClick={handleToggleChangeExperienceOptionsModal}
            menuCaller={getExperienceRef(experienceToEdit.id)}
          />
        )}
        {isChangeCommuteModalOpen && commuteToEdit && (
          <ChangeCommuteOptionsModal
            commute={commuteToEdit}
            itineraryId={itineraryId}
            onClose={handleToggleChangeCommuteOptionsModal}
            isOpen={isChangeCommuteModalOpen}
          />
        )}
      </main>
    </>
  );
};

const toggleModalState = (
  setter: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setter((prevState) => !prevState);
};

function isAnyModalOpen(modalStates: boolean[]) {
  return modalStates.some((isOpen) => isOpen);
}
