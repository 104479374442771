import { BlogCategory, Commute } from '@/generated';
import { TopicType } from '@/stores/blogStore';

export const ELEVEN_SECONDS = 11000;
export const EIGHT_SECONDS = 8000;
export const FIVE_SECONDS = 5000;
export const THREE_SECONDS = 3000;
export const TWO_SECONDS = 2000;
export const TWO_AND_HALF_SECONDS = 2500;
export const SECOND = 1000;

export const PROGRESS_PART = 5;

export const FIRST_PAGE_NUMBER = 1;
export const PAGE_SIZE = 20;

export const TRANSPORT_MODES = [
  Commute.travel_mode.PEDESTRIAN,
  Commute.travel_mode.CAR,
  Commute.travel_mode.TAXI,
  Commute.travel_mode.BUS,
  Commute.travel_mode.BICYCLE,
  Commute.travel_mode.MOTORCYCLE,
];

export const DEBOUNCE_TIME_MS = 400;

export const COMING_SOON_MESSAGE =
  'Coming soon!\nThis feature is still in development and will be released soon.';

export const MAX_DATE_RANGE = 7;

export const DATE_MONTH_FORMAT = 'MMM';
export const DATE_DAY_FORMAT = 'DD';
export const DATE_WEEK_DAY_SHORT_FORMAT = 'ddd';
export const DATE_DAY_MONTH_DAY_FORMAT = 'ddd, MMM D, ';
export const DATE_WEEK_DAY_SHORT_MONTH_DAY_FORMAT = 'ddd, MMM DD';
export const DATE_MONTH_YEAR_FORMAT = 'MMMM YYYY';
export const YEAR_MONTH_DAY_FORMAT = 'YYYY-MM-DD';
export const YEAR_MONTH_DAY_TIME_FORMAT = 'YYYY-MM-DD h:mm A';
export const DATE_DAY_MONTH_YEAR_FORMAT = 'MM/DD/YYYY';
export const DATE_DAY_EXTENDED_MONTH_DAY_FORMAT = 'ddd, MMM DD';

export const TIME_HOUR_MINUTE_SECONDS_FORMAT = 'HH:mm:ss';
export const TIME_HOUR_MINUTE_WITHOUT_SECONDS_FORMAT = 'HH:mm';
export const TWELVE_HOURS_TIME_HOUR_MINUTE_WITHOUT_SECONDS_FORMAT = 'hh:mm';
export const TIME_HOUR_MINUTE_FORMAT = 'h:mm';
export const TIME_HOUR_MINUTE_MERIDIAN_FORMAT = 'hh:mm a';
export const TIME_HOUR_MINUTE_MERIDIAN_WITHOUT_SPACE_FORMAT = 'hh:mmA';

export const TIME_MERIDIAN_FORMAT = 'A';

export const MISSING_INPUT_VALUES_MESSAGE = 'Missing input values';

export const IG_LINK = 'https://www.instagram.com/travelpal.ai/';
export const FB_LINK = 'https://www.facebook.com/profile.php?id=61554427824664';
export const X_LINK = 'https://twitter.com/travelpalai';

export const FOOTER_COPYRIGHT_TEXT = `${new Date().getFullYear()}, Travelpal © All rights reserved.`;

export const GOOGLE_MAPS_TAILORING_BOUNDARY_RADIUS = 500;

// TODO: temprary constant to show different content on the dev environment
export const DEV_ENV_URL = 'https://www.dev.travelpal.ai/';

export const TIME_SLOT_DIFFERENCE = 15;
export const MAX_SPECIFIC_THINGS_TO_INCLUDE = 1;
export const FOOD_PREFERENCES_INDEX = 1;
export const MIN_FOOD_PREFERENCES_NUMBER = 3;

export const SMALL_MOBILE_BREAKPOINT = 320;
export const MEDIUM_MOBILE_BREAKPOINT = 376;
export const LARGE_MOBILE_BREAKPOINT = 545;
export const TABLET_BREAKPOINT = 768;
export const DESKTOP_BREAKPOINT = 1025;
export const LARGE_DESKTOP_BREAKPOINT = 1440;

/*        BLOG CONSTANTS        */
export const DEFAULT_PAGINATION_LIMIT = 3;

export const DEFAULT_BLOG_CATEGORY: BlogCategory = {
  uuid: undefined,
  name: 'All Categories',
  slug: 'all-categories',
};
export const DEFAULT_BLOG_TOPIC: TopicType = {
  title: 'Our Blog',
  slug: 'home',
  useDataHook: undefined,
};

/*      HELMETS      */
export const DEFAULT_ITINERARY_PAGE_TITLE = 'TravelPal';
export const DEFAULT_ITINERARY_PAGE_DESCRIPTION =
  'TravelPal is your ultimate travel guide. Plan your perfect trip today with expert advice.';
export const DEFAULT_ITINERARY_PAGE_KEYWORDS =
  'Travel, Travel Guide, Travel Tips, Destinations, Vacation, Adventure';

export const DEFAULT_BLOG_PAGE_TITLE =
  'TravelPal Blog - Your Ultimate Travel Guide';
export const DEFAULT_BLOG_PAGE_DESCRIPTION =
  'Explore travel tips, destination guides, and more with TravelPal. Plan your perfect trip today with expert advice.';
export const DEFAULT_BLOG_PAGE_KEYWORDS =
  'Travel, Travel Guide, Travel Tips, Destinations, Vacation, Adventure, Blog';
export const DEFAULT_BLOG_PAGE_OG_TITLE =
  'TravelPal Blog - Your Ultimate Travel Guide';
export const DEFAULT_BLOG_PAGE_OG_DESCRIPTION =
  'Get the latest travel tips and guides to make your next trip unforgettable. Plan your journey with TravelPal today.';
export const DEFAULT_BLOG_PAGE_OG_IMAGE = '/favicon-32x32.png';
export const DEFAULT_BLOG_PAGE_OG_URL = 'https://travelpal.ai/blog';
export const DEFAULT_BLOG_PAGE_TWITTER_CARD = 'summary_large_image';
export const DEFAULT_BLOG_PAGE_TWITTER_TITLE =
  'TravelPal Blog - Your Ultimate Travel Guide';
export const DEFAULT_BLOG_PAGE_TWITTER_DESCRIPTION =
  "Plan the perfect vacation with TravelPal's blog, featuring expert travel tips and destination guides.";
export const DEFAULT_BLOG_PAGE_TWITTER_IMAGE = '/favicon-32x32.png';
export const DEFAULT_BLOG_PAGE_CANONICAL_URL = 'https://travelpal.ai/blog';
