import { Button } from '@components/buttons/Button';
import { MenuItem } from '@components/menu/MenuItem';
import classNames from 'classnames';
import { useRef } from 'react';

import { ItemsSpacing, MenuItemType } from '@/types/MenuTypes';

import { WindowSize } from '@/hooks/useWindowSize';
import { StartPageModal } from '../modals/StartPageModal';
import { useLandingPageMenu } from '@/hooks/useLandingPageMenu';
import { LARGE_MOBILE_BREAKPOINT } from '@/utils/constants';

export type LandingPageMenuProps = {
  roundedItems?: boolean;
  itemsSpacing?: ItemsSpacing;
  itemsVariant?: MenuItemType;
  isEditMode?: boolean;
  isLoading?: boolean;
  isBigButton?: boolean;
  isBorderNeeded?: boolean;
  styles?: string;
  currentWindow?: WindowSize;
  handleGenerateItinerary: () => void;
};

export const LandingPageMenu = ({
  roundedItems,
  isEditMode,
  itemsVariant = 'dark',
  itemsSpacing = 'small',
  isLoading = false,
  isBigButton = false,
  isBorderNeeded = false,
  styles,
  currentWindow,
  handleGenerateItinerary,
}: LandingPageMenuProps) => {
  const { openedModal, setOpenedModal, handlePlanTrip, menuItems } =
    useLandingPageMenu();

  const buttonStyles: React.CSSProperties = {
    margin: 0,
    height: '72px',
    justifyContent: 'center',
  };
  const buttonStylesMedium: React.CSSProperties = {
    height: '56px',
    justifyContent: 'center',
  };
  const buttonStylesSmall: React.CSSProperties = {
    height: '56px',
    justifyContent: 'center',
    width: '100%',
  };

  const itemCallerRef = useRef<{
    [key: string]: HTMLDivElement | null;
  }>({});

  return (
    <div
      className={classNames(
        'flex max-w-[342px] mdm:max-w-none w-full space-y-3',
        'mdm:flex-grow lg:gap-x-3 lg:items-center',
        {
          'flex-col': true,
          'lg:flex-row lg:space-y-0 lg:space-x-3': true,
          [styles ?? '']: !!styles,
        },
      )}
    >
      <ul
        className={classNames(
          'flex list-none border-none bg-clip-padding overflow-hidden rounded-xl text-left',
          'flex-col mdm:flex-row lg:justify-end lg:flex-grow lg:rounded-36',
          {
            'gap-[1px]': itemsSpacing === 'small',
            'gap-3': itemsSpacing === 'large',
            'gap-0': itemsSpacing === 'none',
          },
        )}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.id}
            onClick={item.onClick}
            text={item.text}
            withoutData={item.withoutData}
            iconName={item.iconName}
            paddingVertical={4}
            variant={itemsVariant}
            isRounded={roundedItems}
            isDisabled={isLoading}
            isEditMode={isEditMode}
            styles={classNames(
              'lg:py-6 relative',
              `${isBorderNeeded && item.id != 3 ? 'partial-border' : ''}`,
              {
                'mdm:w-2/3': item.id !== 1,
                'mdm:w-full': item.id === 1,
              },
            )}
            ref={(ref) => {
              itemCallerRef.current[item.id] = ref;
            }}
          />
        ))}
      </ul>
      {openedModal ? (
        <StartPageModal
          openedModal={openedModal}
          setOpenedModal={setOpenedModal}
          menuCaller={itemCallerRef}
        />
      ) : null}
      <Button
        onClick={() => {
          handlePlanTrip(handleGenerateItinerary);
        }}
        isLoading={isLoading}
        iconName="stars"
        size="max"
        full={
          currentWindow?.windowSize
            ? currentWindow.windowSize < LARGE_MOBILE_BREAKPOINT
            : false
        }
        styles={
          isBigButton
            ? buttonStyles
            : currentWindow?.windowSize &&
              currentWindow.windowSize < LARGE_MOBILE_BREAKPOINT
            ? buttonStylesSmall
            : buttonStylesMedium
        }
      >
        Plan your trip
      </Button>
    </div>
  );
};
