import { ExperienceFilterBottomSheet } from '@components/bottomSheets/ExperienceFilterBottomSheet';
import { ExperienceMapBottomSheet } from '@components/bottomSheets/ExperienceMapBottomSheet';
import { ExperienceSortByBottomSheet } from '@components/bottomSheets/ExperienceSortByBottomSheet';
import { TimeSlotBottomSheet } from '@components/bottomSheets/timeSlotBottomSheet/TimeSlotBottomSheet';
import { Button } from '@components/buttons/Button';
import { AddExperienceHeader } from '@components/headers/AddExperienceHeader';
import { AddExperienceOptionsModal } from '@components/modals/AddExperienceOptionsModal';
import { AddExperienceSection } from '@components/sections/AddExperienceSection';
import { useGetFeaturedExperiences } from '@hooks/useGetFeaturedExperiences';
import { useRedirectOnInvalidItineraryId } from '@hooks/useRedirectOnInvalidItineraryId';
import { useAddExperienceStore } from '@stores/addExperienceStore';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Experience } from '@/generated';
import { ExperienceMap } from '@/components/map/ExperienceMap';
import classNames from 'classnames';

export const AddExperiencePage = () => {
  const [searchValue, setSearchValue] = useState('');
  const [isFilterSheetOpen, setIsFilterSheetOpen] = useState(false);
  const [isSortingSheetOpen, setIsSortingSheetOpen] = useState(false);
  const [isMapSheetOpen, setIsMapSheetOpen] = useState(false);
  const [isAddExperienceModalOpen, setIsAddExperienceModalOpen] =
    useState(false);
  const [isTimeSlotBottomSheetOpen, setIsTimeSlotBottomSheetOpen] =
    useState(false);

  const { id } = useParams();

  useRedirectOnInvalidItineraryId(id);

  const itineraryId = id ? Number(id) : undefined;

  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useGetFeaturedExperiences(searchValue);

  const handleSetExperience = useAddExperienceStore(
    (state) => state.setChosenExperience,
  );

  const chosenExperience = useAddExperienceStore(
    (state) => state.chosenExperience,
  );
  const chosenFilters = useAddExperienceStore((state) => state.chosenFilters);

  const handleFilterClick = useAddExperienceStore(
    (state) => state.setChosenFilters,
  );

  const handleClearFiltersClick = useAddExperienceStore(
    (state) => state.clearFilters,
  );

  const handleToggleFilterSheet = () =>
    setIsFilterSheetOpen(!isFilterSheetOpen);
  const handleToggleSortingSheet = () =>
    setIsSortingSheetOpen(!isSortingSheetOpen);
  const handleToggleIsMapSheetOpen = () => setIsMapSheetOpen(!isMapSheetOpen);
  const handleToggleIsAddExperienceModalOpen = () =>
    setIsAddExperienceModalOpen(!isAddExperienceModalOpen);
  const handleToggleIsTimeSlotBottomSheetOpen = () =>
    setIsTimeSlotBottomSheetOpen(!isTimeSlotBottomSheetOpen);

  const handleExperienceCardPlusClick = (experience: Experience) => {
    handleSetExperience(experience);
    handleToggleIsAddExperienceModalOpen();
  };

  const sortExperiencesCallerRef = useRef<HTMLButtonElement | null>(null);
  const addExperienceCallerRef = useRef<{
    [key: string]: HTMLButtonElement | null;
  }>({});

  const getExperienceRef = (
    experienceId: number | undefined,
  ): HTMLButtonElement | null => {
    if (!experienceId) return null;
    return addExperienceCallerRef.current[experienceId] || null;
  };

  return (
    <div className="flex w-full h-full lg:grid lg:grid-cols-12">
      <div
        className={classNames(
          'flex flex-col h-svh overflow-y-hidden',
          'lg:col-span-4',
          {
            'lg:hidden': isFilterSheetOpen,
          },
        )}
      >
        <AddExperienceHeader
          experiences={data}
          itineraryId={itineraryId}
          onFilterClick={handleToggleFilterSheet}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
        />
        <AddExperienceSection
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          experiences={data}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
          onExperienceCardPlusClick={handleExperienceCardPlusClick}
          onSortByClick={handleToggleSortingSheet}
          ref={addExperienceCallerRef}
          sortMenuCallerRef={sortExperiencesCallerRef}
        />
        <div className="flex w-full fixed z-20 bottom-3 justify-center lg:hidden">
          <Button
            variant="secondary"
            iconName="mapbutton"
            size="large"
            hasShadow
            onClick={handleToggleIsMapSheetOpen}
            disabled={!data?.length}
          >
            Map
          </Button>
        </div>
        <ExperienceSortByBottomSheet
          isOpen={isSortingSheetOpen}
          onClose={handleToggleSortingSheet}
          menuCaller={sortExperiencesCallerRef.current}
        />
        <ExperienceMapBottomSheet
          mapFiltersType="categories"
          isOpen={isMapSheetOpen}
          onClose={handleToggleIsMapSheetOpen}
          dateExperiences={data}
          chosenFilters={chosenFilters.categories}
          onFilterClick={handleFilterClick}
          handleToggleFilterSheet={handleToggleFilterSheet}
          commutes={undefined}
          selectedDate={null}
        />
        <AddExperienceOptionsModal
          onAddToTripClick={handleToggleIsTimeSlotBottomSheetOpen}
          onOverlayClick={handleToggleIsAddExperienceModalOpen}
          isOpen={isAddExperienceModalOpen}
          onClose={handleToggleIsAddExperienceModalOpen}
          menuCaller={getExperienceRef(chosenExperience?.id)}
        />
        <TimeSlotBottomSheet
          itineraryId={itineraryId}
          isOpen={isTimeSlotBottomSheetOpen}
          onClose={handleToggleIsTimeSlotBottomSheetOpen}
          isUpdate={false}
          handleUpdateExperience={undefined}
        />
      </div>
      <div
        className={classNames('z-10 lg:col-span-4 h-svh flex flex-col', {
          'lg:hidden': !isFilterSheetOpen,
        })}
      >
        <ExperienceFilterBottomSheet
          experiences={data}
          isOpen={isFilterSheetOpen}
          onClose={handleToggleFilterSheet}
          chosenFilters={chosenFilters.categories}
          onFilterClick={handleFilterClick}
          onClearFiltersClick={handleClearFiltersClick}
        />
      </div>
      <div className="hidden lg:flex lg:col-span-8 w-full h-full">
        <ExperienceMap
          experiences={data || []}
          commutes={[]}
          filtersType={'categories'}
          chosenFilters={chosenFilters.categories}
          onFilterClick={handleFilterClick}
          handleToggleFilterSheet={handleToggleFilterSheet}
          selectedDate={null}
          setSelectedDate={undefined}
        />
      </div>
    </div>
  );
};
