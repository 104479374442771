import { ArticleItem } from './ArticleItem';
import { TopicType } from '@/stores/blogStore';
import { BlogCategory } from '@/generated';
import classNames from 'classnames';
import { useWindowSize } from '@/hooks/useWindowSize';
import { ViewMoreButton } from '@/components/buttons/ViewMoreButton';
import { UseArticlesHook } from '@/hooks/blog/useGetArticles';
import { ArticleItemLoadingPlaceholder } from './ArticleItemLoadingPlaceholder';

type Props = {
  topic?: TopicType;
  activeCategory?: BlogCategory;
  isFirstItemHighlighted?: boolean;
  onViewMore?: (filter: TopicType) => void;
  onArticleClick: (articleSlug: string) => void;
  onCategoryClick: (category: BlogCategory) => void;
  page?: number;
  pageSize?: number;
  useDataHook: () => UseArticlesHook;
};

export const ArticleSection = ({
  topic,
  isFirstItemHighlighted = false,
  onViewMore,
  onCategoryClick,
  onArticleClick,
  useDataHook,
}: Props) => {
  const { windowType } = useWindowSize();
  const { articles, isArticlesLoading } = useDataHook();

  const renderLoadingPlaceholder = () => (
    <div
      className={classNames(
        'flex flex-col gap-y-[32px] mdx:gap-y-[48px] h-full',
        {
          'mt-6 mdx:mt-[48px]': isFirstItemHighlighted,
        },
      )}
    >
      <div className="flex justify-between items-center px-2 mdx:px-7 h-[32px]">
        <div
          className={classNames(
            'leading-[32px] tracking-[-0.04em] h-full',
            'mdx:text-[60px] mdx:leading-[60px] w-[75%] animate-pulse bg-gray rounded-32',
          )}
        />
        <div
          className={classNames(
            'flex justify-between px-2 mdx:px-7 hidden mdx:flex w-[100px] h-full ',
            'animate-pulse bg-gray rounded-32',
          )}
        />
      </div>
      <div className="flex gap-x-5 overflow-x-auto scrollbar-hide px-2 mdx:px-7">
        <ArticleItemLoadingPlaceholder />
        <ArticleItemLoadingPlaceholder />
        <ArticleItemLoadingPlaceholder />
      </div>
      <div
        className={classNames(
          'flex justify-between mx-2 mdx:mx-7 mdx:hidden flex h-[56px] ',
          'animate-pulse bg-gray rounded-32',
        )}
      />
    </div>
  );

  const renderViewMoreButton = (isMobile: boolean) => (
    <div
      className={`flex justify-between px-2 mdx:px-7 
        ${isMobile ? 'flex mdx:hidden' : 'hidden mdx:flex'}`}
    >
      <ViewMoreButton onClick={() => onViewMore?.(topic!)} />
    </div>
  );

  const renderHighlightedItems = () => (
    <div className="grid grid-flow-col auto-cols-[minmax(300px,1fr)] gap-x-5 overflow-x-auto scrollbar-hide ml-2 mdx:ml-7">
      {articles.map((item, index) => (
        <div
          key={index}
          className={classNames('min-h-[400px] flex flex-col', {
            'mr-2 mdx:mr-7': index === articles.length - 1,
          })}
        >
          <div className="flex-1">
            <ArticleItem
              {...item}
              onCategoryClick={onCategoryClick}
              variant="default"
              onArticleClick={() => onArticleClick(item.slug)}
            />
          </div>
        </div>
      ))}
    </div>
  );

  const renderGridItems = () => (
    <div
      className={classNames(
        'flex flex-col lg:grid lg:grid-cols-2 lg:grid-rows-2',
        'w-full h-full mt-6 mdx:mt-[48px] gap-x-5 gap-y-6 px-2 mdx:px-7',
      )}
    >
      <ArticleItem
        {...articles[0]}
        direction="column"
        onCategoryClick={onCategoryClick}
        divStyles="col-span-1 row-span-2 flex flex-col min-h-[461px]"
        onArticleClick={() => onArticleClick(articles[0].slug)}
      />
      {windowType === 'lg' ? (
        articles.slice(1, 3).map((item, index) => (
          <div key={index} className="col-span-1 row-span-1 w-full h-full">
            <ArticleItem
              {...item}
              direction="row"
              onCategoryClick={onCategoryClick}
              onArticleClick={() => onArticleClick(item.slug)}
              variant="default"
            />
          </div>
        ))
      ) : (
        <div className="flex col-span-1 row-span-1 w-full h-full gap-x-2 mt-3 overflow-auto scrollbar-hide">
          {articles.slice(1).map((item, index) => (
            <ArticleItem
              key={index}
              {...item}
              direction="column"
              onCategoryClick={onCategoryClick}
              onArticleClick={() => onArticleClick(item.slug)}
              variant="default"
            />
          ))}
        </div>
      )}
    </div>
  );

  if (articles.length === 0) return null;

  return (
    <>
      {isArticlesLoading && renderLoadingPlaceholder()}
      {!isArticlesLoading && (
        <div className="flex flex-col gap-y-[32px] mdx:gap-y-[48px]">
          {topic && (
            <div className="flex justify-between items-center px-2 mdx:px-7">
              <h4
                className={classNames(
                  'text-h3 uppercase font-[500] text-[32px] leading-[32px] tracking-[-0.04em]',
                  'mdx:text-[60px] mdx:leading-[60px]',
                )}
              >
                {topic.title}
              </h4>
              {topic && renderViewMoreButton(false)}
            </div>
          )}
          {!isFirstItemHighlighted
            ? renderHighlightedItems()
            : renderGridItems()}
          {topic && renderViewMoreButton(true)}
        </div>
      )}
    </>
  );
};
