import { HeaderWithAuth } from '@/components/headers/HeaderWithAuth';
import classNames from 'classnames';
import { forwardRef } from 'react';

export const HeroBlog = forwardRef<HTMLDivElement, { activeTopic?: string }>(
  ({ activeTopic }, ref) => {
    return (
      <div
        className={classNames(
          'relative bg-no-repeat bg-cover bg-top mdx:bg-center bg-blog-hero mx-2',
          'flex flex-col rounded-32 shrink-0 h-[365px] mdx:h-[600px] mdx:mx-7 mdx:my-7',
        )}
        ref={ref}
      >
        <HeaderWithAuth />
        <div
          className={classNames(
            'flex flex-col w-full h-full items-center justify-center',
            'gap-y-4 mdx:gap-y-[48px]',
          )}
        >
          <h1
            className={classNames(
              'text-h1 text-white text-center uppercase',
              'mdx:text-[88px] tracking-[-0.04em] leading-[83.6px]',
            )}
          >
            {activeTopic?.split(' ').slice(0, 2).join(' ') || 'our blog'}
          </h1>
          <p
            className={classNames(
              'text-p-web text-white text-center px-[19.5px]',
              'leading-[23.68px] tracking-[-0.04em]',
              'mdx:text-[24px] mdx:leading-[24px]',
            )}
          >
            Discover insights, stories, and teams for effortless journeys with
            AI-powered navigation
          </p>
        </div>
      </div>
    );
  },
);
