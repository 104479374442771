import { useEffect, useState } from 'react';

export const useLoadPlacesLibrary = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isLoaded) return;

    // Check if the Google Maps API script is already loaded
    const existingScript = document.querySelector(`script[src="https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE_GOOGLE_API_KEY}&libraries=places&loading=async&callback=initSearch"]`);

    if (existingScript) {
      setIsLoaded(true);
      return;
    }

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE_GOOGLE_API_KEY}&libraries=places&loading=async&callback=initSearch`;
    script.async = true;

    document.body.appendChild(script);

    script.onload = () => {
      setIsLoaded(true);
    };

    script.onerror = () => {
      console.error('Failed to load Google Places Library');
      setIsLoaded(false);
    };

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return {
    isLibraryLoaded: isLoaded,
  };
};
