import classNames from 'classnames';

type Props = {
  variant?: 'default' | 'title-only';
  direction?: 'row' | 'column';
  divStyles?: string;
};

export const ArticleItemLoadingPlaceholder = ({
  variant = 'default',
  direction = 'column',
  divStyles,
}: Props) => {
  if (variant === 'title-only') {
    return (
      <div className="flex bg-gray-light w-full rounded-24 p-6 cursor-pointer animate-pulse">
        <div className="bg-gray w-3/4 h-6 rounded-32"></div>
      </div>
    );
  }

  if (direction === 'row') {
    return (
      <div
        className={classNames(
          'flex w-full h-full gap-x-5 min-w-[359px]',
          divStyles,
        )}
      >
        <div className="relative w-full h-full">
          <div className="w-full h-full bg-gray rounded-32 animate-pulse"></div>
        </div>
        <div className="flex h-full w-full shrink-0 gap-x-5">
          <div className="bg-gray w-1/2 h-[200px] mdx:h-[300px] rounded-32 animate-pulse mb-2"></div>
          <div className="flex flex-col w-1/2">
            <div className="bg-gray w-3/4 h-4 rounded-32 animate-pulse mb-2"></div>
            <div className="bg-gray w-1/3 h-4 rounded-32 animate-pulse mb-4"></div>
            <div className="bg-gray w-1/4 h-4 rounded-32 animate-pulse"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'flex flex-col w-full h-full gap-y-6 gap-x-6 min-w-[250px]',
        divStyles,
      )}
    >
      <div className="relative w-full h-full">
        <div className="w-full h-full bg-gray rounded-32 animate-pulse"></div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="bg-gray w-full h-[200px] mdx:h-[300px] rounded-32 animate-pulse mb-2"></div>
        <div className="bg-gray w-1/5 h-4 rounded-32 animate-pulse mb-2"></div>
        <div className="bg-gray w-2/5 h-4 rounded-32 animate-pulse mb-4"></div>
        <div className="bg-gray w-4/5 h-4 rounded-32 animate-pulse"></div>
      </div>
    </div>
  );
};
