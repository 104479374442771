import {
  SaveClickHandlerProp,
  TailoringTimeSlotBottomSheet,
} from '@components/bottomSheets/TailoringTimeSlotBottomSheet';
import { Button } from '@components/buttons/Button';
import { TextButton } from '@components/buttons/TextButton';
import { Header } from '@components/headers/Header';
import { ItineraryLoader } from '@components/loaders/ItineraryLoader';
import { ProgressBar } from '@components/progressBar/ProgressBar';
import { ErrorSection } from '@components/sections/ErrorSection';
import { BudgetStep } from '@components/tailoringSteps/BudgetStep';
import { FoodPreferences } from '@components/tailoringSteps/FoodPreferences';
import { Interests } from '@components/tailoringSteps/Interests';
import { PlaceOfStay } from '@components/tailoringSteps/PlaceOfStay';
import { Plans } from '@components/tailoringSteps/Plans';
import { useMultistepForm } from '@hooks/useMultistepForm';
import { useTailorItinerary } from '@hooks/useTailorItinerary';
import { useInitialSearchStore } from '@stores/initialSearchStore';
import { motion } from 'framer-motion';
import { useState } from 'react';

import { useTooltipsStore } from '@/stores/itineraryTooltipsStore';
import { ActivityTag, FromToTimeRange } from '@/types/AppTypes';
import { FoodPreferenceType } from '@/types/FoodPreference';
import { useTailorSearchStore } from '@/stores/tailorSearchStore';
import { showToast } from '@/utils/showToast';
import classNames from 'classnames';
import {
  FOOD_PREFERENCES_INDEX,
  MIN_FOOD_PREFERENCES_NUMBER,
} from '@/utils/constants';

type Props = {
  itineraryId: number;
  onClose: () => void;
  activity_tags: ActivityTag[] | undefined;
  toastMessage?: string;
};

export const TailoringModal = ({
  itineraryId,
  onClose,
  activity_tags,
  toastMessage,
}: Props) => {
  const [_, setAvailableInterests] = useState<ActivityTag[]>([]);
  const [availableFoodPreferences, setAvailableFoodPreferences] = useState<
    FoodPreferenceType[]
  >([]);
  const [isTimeSlotBottomSheetOpen, setIsTimeSlotBottomSheetOpen] =
    useState(false);
  const [timeRangePlans, setTimeRangePlans] = useState<FromToTimeRange[]>([]);
  const [timeRangeToUpdate, setTimeRangeToUpdate] =
    useState<FromToTimeRange | null>(null);

  const { tailorItinerary, isPending, error } = useTailorItinerary({
    onClose,
    itineraryId,
    toastMessage,
  });

  const didShowTailoringTooltips = useTooltipsStore(
    (state) => state.didShowTailoringTooltips,
  );

  const initialSearchDateRange = useInitialSearchStore(
    (state) => state.dateRange,
  );

  const handleSetDidShowTailoringTooltips = useTooltipsStore(
    (state) => state.setDidShowTailoringTooltips,
  );

  const variants = {
    hidden: { y: '100svh' },
    visible: {
      y: '0',
      transition: { type: 'tween', stiffness: 60, damping: 10 },
    },
    exit: { y: '-100svh', transition: { ease: 'easeInOut' } },
  };

  const handleAddTimeRangePlan = ({
    selectedDay,
    timeRangeToUpdate,
    from,
    to,
    meridianFrom,
    meridianTo,
  }: SaveClickHandlerProp) => {
    if (timeRangeToUpdate) {
      const updatedTimeRangePlans = timeRangePlans.map((timerange) => {
        if (JSON.stringify(timerange) === JSON.stringify(timeRangeToUpdate)) {
          return {
            date: selectedDay,
            from: from,
            meridianFrom: meridianFrom,
            to: to,
            meridianTo: meridianTo,
          };
        }
        return timerange;
      });

      setTimeRangePlans(updatedTimeRangePlans);
      setTimeRangeToUpdate(null);
      return;
    }

    if (
      timeRangePlans.some(
        (timeRangePlan) =>
          timeRangePlan.date === selectedDay &&
          timeRangePlan.from === from &&
          timeRangePlan.meridianFrom === meridianFrom &&
          timeRangePlan.to === to &&
          timeRangePlan.meridianTo === meridianTo,
      )
    ) {
      return;
    }

    setTimeRangePlans([
      ...timeRangePlans,
      {
        date: selectedDay,
        from: from,
        to: to,
        meridianFrom,
        meridianTo,
      },
    ]);
  };

  const handleToggleIsTimeSlotBottomSheetOpen = () =>
    setIsTimeSlotBottomSheetOpen(!isTimeSlotBottomSheetOpen);

  const tailoredSearch = useTailorSearchStore((state) => state.tailoredSearch);
  const setTailoredSearch = useTailorSearchStore(
    (state) => state.setTailoredSearch,
  );
  const { currStepIndex, step, steps, back, next, isFirstStep, isLastStep } =
    useMultistepForm([
      <Interests
        activity_tags={activity_tags}
        setAvailableInterests={setAvailableInterests}
        storage={tailoredSearch}
        setStorageSearch={setTailoredSearch}
      />,
      <FoodPreferences
        availableFoodPreferences={availableFoodPreferences}
        setAvailableFoodPreferences={setAvailableFoodPreferences}
        storage={tailoredSearch}
        setStorageSearch={setTailoredSearch}
      />,
      <BudgetStep
        storage={tailoredSearch}
        setStorageSearch={setTailoredSearch}
      />,
      <Plans
        timeRangePlans={timeRangePlans}
        onAddTimeRangeButtonClick={handleToggleIsTimeSlotBottomSheetOpen}
        setTimeRangeToUpdate={setTimeRangeToUpdate}
      />,
      <PlaceOfStay />,
    ]);

  const onNextWrapper = () => {
    if (
      currStepIndex === FOOD_PREFERENCES_INDEX &&
      (tailoredSearch.foodPreferences
        ? tailoredSearch.foodPreferences.length < MIN_FOOD_PREFERENCES_NUMBER
        : true)
    ) {
      showToast(
        `Please, select at least ${MIN_FOOD_PREFERENCES_NUMBER} food preferences`,
      );
      return;
    }
    next();
  };
  const onSave = async () => {
    const userPlans = timeRangePlans.map((plan) => ({
      date: plan.date,
      start_time: plan.from,
      end_time: plan.to,
    }));

    if (!didShowTailoringTooltips) {
      handleSetDidShowTailoringTooltips(true);
    }

    await tailorItinerary(userPlans);
  };

  if (isPending) {
    return <ItineraryLoader dateRange={initialSearchDateRange} />;
  }

  if (!isPending && error) {
    return <ErrorSection errorMessage={error} />;
  }

  return (
    <>
      <motion.div
        variants={variants}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="absolute px-4 lg:px-5 py-2 lg:py-0 top-0 left-0 z-10 h-svh w-full bg-white flex flex-col items-center"
      >
        <div className="space-y-2 w-full">
          <Header
            actionButtonText="Save & close"
            onBackButtonClick={onClose}
            onActionButtonClick={onSave}
            styles="lg:py-[19px]"
          />
          <ProgressBar active={currStepIndex + 1} steps={steps.length} />
        </div>
        {step}
        <div
          className={classNames(
            'flex w-full max-w-[375px] items-center',
            'lg:max-w-none lg:pb-8 lg:px-[19px]',
            {
              'justify-between': !isFirstStep,
              'justify-end': isFirstStep,
            },
          )}
        >
          {!isFirstStep && (
            <TextButton padding="2" onClick={back}>
              Back
            </TextButton>
          )}
          <Button
            size="large"
            onClick={isLastStep ? onSave : onNextWrapper}
            variant="primary"
          >
            {isLastStep ? 'Save & close' : 'Next'}
          </Button>
        </div>
      </motion.div>
      <TailoringTimeSlotBottomSheet
        isOpen={isTimeSlotBottomSheetOpen}
        onClose={handleToggleIsTimeSlotBottomSheetOpen}
        onSaveClick={handleAddTimeRangePlan}
        timeRangeToUpdate={timeRangeToUpdate}
      />
    </>
  );
};
