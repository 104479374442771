import { useState, useEffect } from 'react';
import defaultPlaceholderImage from '@assets/images/landingPage/experience-placeholder.webp';
import classNames from 'classnames';
import { BlogPost } from '@/generated';

export type ImageSrcSet = {
  src: string;
  type: 'small' | 'medium' | 'large';
};

export const convertBlogPostToImageSrcSet = (
  blogPost: BlogPost,
): ImageSrcSet[] => {
  const imageSrcSet: ImageSrcSet[] = [];

  if (blogPost.image_small) {
    imageSrcSet.push({
      src: blogPost.image_small,
      type: 'small',
    });
  }

  if (blogPost.image_medium) {
    imageSrcSet.push({
      src: blogPost.image_medium,
      type: 'medium',
    });
  }

  if (blogPost.image_large) {
    imageSrcSet.push({
      src: blogPost.image_large,
      type: 'large',
    });
  }

  return imageSrcSet;
};

type Props = {
  image: string | undefined | null | ImageSrcSet[];
  placeholderImg?: string;
  alt?: string;
  displayImage?: boolean;
  overrideStylesLoadingDiv?: string;
  overrideStylesImage?: string;
};

export const ImageContainer = ({
  image,
  placeholderImg,
  alt,
  displayImage = false,
  overrideStylesImage,
  overrideStylesLoadingDiv,
}: Props) => {
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(true);
  const [imageToDisplay, setImageToDisplay] = useState<
    string | undefined | null | ImageSrcSet[]
  >(image);
  const placeholder = placeholderImg ?? defaultPlaceholderImage;

  // Cleanup state when component unmounts
  useEffect(() => {
    return () => setIsLoadingImage(false);
  }, []);

  // Function to generate the srcSet string for responsive images
  const generateSrcSet = (images: ImageSrcSet[] | undefined | null): string => {
    if (!images || !Array.isArray(images)) return '';
    return images
      .map((img) => {
        if (img.type === 'small') return `${img.src} 320w`;
        if (img.type === 'medium') return `${img.src} 640w`;
        if (img.type === 'large') return `${img.src} 1024w`;
        return '';
      })
      .join(', ');
  };

  return (
    <div className="absolute w-full h-full flex rounded-32 left-0 top-0">
      {(!displayImage || isLoadingImage) && (
        <div
          className={classNames(
            'animate-pulse bg-gray-medium absolute w-full h-full inset-0 rounded-32',
            {
              [overrideStylesLoadingDiv ?? '']: !!overrideStylesLoadingDiv,
            },
          )}
        />
      )}
      <img
        loading="lazy"
        src={
          typeof imageToDisplay === 'string'
            ? imageToDisplay
            : imageToDisplay?.[0]?.src ?? placeholder
        }
        srcSet={
          Array.isArray(imageToDisplay) ? generateSrcSet(imageToDisplay) : ''
        }
        sizes="(max-width: 640px) 100vw, (max-width: 1024px) 50vw, 33vw"
        className={classNames(
          'absolute h-full w-full object-cover object-center inset-0 transition-opacity duration-300',
          {
            [overrideStylesImage ?? '']: !!overrideStylesImage,
            'opacity-0': !displayImage || isLoadingImage,
            'opacity-100': displayImage && !isLoadingImage,
          },
        )}
        onLoad={() => setIsLoadingImage(false)}
        onError={() => setImageToDisplay(placeholder)}
        alt={alt ?? 'travel experience'}
      />
    </div>
  );
};
