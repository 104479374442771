import { queryKeyTypes } from '@api/queryKeyTypes';
import { useApi } from '@api/serviceProvider.ts';
import { useGetMyItineraryDetails } from '@hooks/useGetMyItineraryDetails';
import { useAddExperienceStore } from '@stores/addExperienceStore';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FreeSlot } from '@/generated';
import { showToast } from '@/utils/showToast';

type Props = {
  itineraryId: number | undefined;
  overlappingExperiences?: number[];
  onTimeSlotSheetClose: () => void;
};

export const useAddExperienceToItinerary = ({
  itineraryId,
  overlappingExperiences = [],
  onTimeSlotSheetClose,
}: Props) => {
  const apiFactory = useApi();
  const [selectDay, setSelectDay] = useState('');
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<FreeSlot>({
    start_time: '',
    end_times: [''],
  });

  const { itinerary } = useGetMyItineraryDetails(itineraryId);
  const chosenExperience = useAddExperienceStore(
    (state) => state.chosenExperience,
  );

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      if (!chosenExperience?.id || !itinerary?.id) {
        return;
      }

      return apiFactory.api.apiV1ExperiencesAssign(chosenExperience.id, {
        itinerary: itinerary.id,
        start_time: selectedTimeSlot.start_time,
        end_time: selectedTimeSlot.end_times[0],
        date: selectDay,
        overlapping_experiences: overlappingExperiences || [],
      });
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.itinerary],
      });
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.commutes],
      });

      navigate(`/itinerary/${itineraryId}`);
      showToast('Experience added successfully');
    },
    onError: () => {
      onTimeSlotSheetClose();
      showToast("We're sorry, we could not add this experience");
    },
  });

  return {
    handleAddExperience: mutate,
    isPending,
    selectDay,
    selectedTimeSlot,
    setSelectDay,
    setSelectedTimeSlot,
  };
};
