import { SpriteIcon, SpriteIconProps } from '@components/icons/SpriteIcon';
import classNames from 'classnames';
import { ReactNode } from 'react';

import { ButtonSizeType, ButtonVariantType } from '@/types/ButtonTypes';

import { Loader } from '../loaders/Loader';

type Props = {
  size: ButtonSizeType;
  onClick: () => void;
  iconName?: SpriteIconProps;
  variant?: ButtonVariantType;
  disabled?: boolean;
  isLoading?: boolean;
  full?: boolean;
  hasShadow?: boolean;
  roundnessVariant?: 'xl' | 'xxl' | 'xxxl';
  bold?: boolean;
  children?: ReactNode;
  type?: 'button' | 'submit';
  styles?: React.CSSProperties;
  iconStyles?: string;
};

export const Button = ({
  size = 'large',
  onClick,
  iconName,
  variant = 'primary',
  disabled,
  isLoading,
  full,
  hasShadow = false,
  roundnessVariant = 'xxl',
  bold = true,
  type = 'button',
  children,
  styles,
  iconStyles,
}: Props) => {
  const iconSizeMap = {
    small: 'w-4 h-4',
    smedium: 'w-5 h-5',
    medium: 'w-5 h-5',
    xmedium: '',
    large: 'w-6 h-6',
    max: 'w-6 h-6',
  };

  const heightMap = {
    small: 'h-8',
    smedium: 'h-10',
    medium: 'h-10',
    xmedium: 'h-[48px]',
    large: 'h-[52px]',
    max: 'h-full',
  };

  const height = heightMap[size] || 'h-[52px]';
  const iconSize = iconSizeMap[size] || 'w-6 h-6';
  const isTertiaryVariant = variant === 'tertiary';
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={classNames('flex justify-center items-center', {
        [height]: height,
        'bg-green hover:bg-green-hover disabled:bg-green-disabled':
          variant === 'primary',
        'bg-gray-light hover:bg-gray-hover disabled:bg-gray-disabled':
          variant === 'secondary',
        'bg-black hover:bg-gray-hover disabled:bg-gray-disabled':
          isTertiaryVariant,
        'bg-white hover:bg-gray-hover disabled:bg-gray-disabled':
          variant === 'quaternary',
        'bg-gray-light bg-opacity-[25%] hover:bg-gray-light-hover disabled:bg-black-disabled':
          variant === 'quinary',
        'w-fit gap-1': size !== 'large',
        'w-full max-w-[160px] gap-2 px-2': size === 'large' && !full,
        'w-full gap-2 px-2': size === 'large' && full,
        'w-full max-w-[200px] gap-2 px-2': size === 'max' && !full,
        'px-3': size === 'small',
        'px-4': size === 'smedium',
        'px-6': size === 'medium',
        'shadow-map': hasShadow,
        'rounded-xl': roundnessVariant === 'xl',
        'rounded-40': roundnessVariant === 'xxl',
        'rounded-360': roundnessVariant === 'xxxl',
      })}
      style={styles}
    >
      {isLoading ? (
        <Loader size="small" dark />
      ) : (
        <>
          {iconName && (
            <SpriteIcon
              iconName={iconName}
              className={`${iconSize} ${
                variant === 'quinary' ? 'fill-white' : ''
              } ${iconStyles}`}
            />
          )}
          {children ? (
            <p
              className={classNames('whitespace-nowrap', {
                'text-white': isTertiaryVariant,
                'text-gray': disabled,
                'text-black': !disabled,
                'text-p': size === 'smedium' || !bold,
                'text-p-medium': size === 'small',
                'text-p-bold h-[18px]':
                  size !== 'small' && size !== 'smedium' && bold,
              })}
            >
              {children}
            </p>
          ) : null}
        </>
      )}
    </button>
  );
};
