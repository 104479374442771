/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Budget } from './Budget';
import type { TailoringAccommodation } from './TailoringAccommodation';
import type { UserPlans } from './UserPlans';
export type Tailoring = {
    food_preferences?: Array<string> | null;
    diets?: Array<string> | null;
    interests?: Array<string> | null;
    companions?: Tailoring.companions | null;
    earliest_start?: string | null;
    accommodation?: TailoringAccommodation;
    budget?: Budget;
    user_plans?: Array<UserPlans> | null;
    things_to_include?: string | null;
};
export namespace Tailoring {
    export enum companions {
        NO_ONE = 'NO ONE',
        PARTNERS = 'PARTNERS',
        FRIENDS = 'FRIENDS',
        FAMILY = 'FAMILY',
        SENIORS = 'SENIORS',
    }
}

