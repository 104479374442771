import { Experience } from '@/generated';

import { AdvancedMarker } from '@vis.gl/react-google-maps';

import { MapPinIcon } from '@components/icons/MapPinIcon';
import { MapItineraryIcon } from '@components/icons/MapItineraryIcon';
import { mapCategoryToIcon } from '@/utils/mapExperienceCategoryToIcon';

type Props = {
  experiences: Experience[];
  handleOnMarkerClick: (experience: Experience) => void;
  selectedExperienceId?: number | null;
  selectedMarkerZIndex?: number;
  defaultMarkerZIndex?: number;
  selectedDate?: string | null;
};
export const MapMarkers = ({
  experiences,
  handleOnMarkerClick,
  selectedExperienceId,
  selectedMarkerZIndex = 999,
  defaultMarkerZIndex = 998,
  selectedDate,
}: Props) => {
  const isSelected = (experience: Experience) => {
    if (
      selectedExperienceId === undefined ||
      selectedExperienceId === null ||
      experience === null
    ) {
      return false;
    }
    return experience.id === selectedExperienceId;
  };

  return experiences.map((experience, index) => (
    <AdvancedMarker
      key={experience.id}
      position={experience.details?.coordinates}
      zIndex={
        isSelected(experience) ? selectedMarkerZIndex : defaultMarkerZIndex
      }
      onClick={() => handleOnMarkerClick(experience)}
    >
      {selectedDate ? (
        <>
          <MapItineraryIcon
            experience={isSelected(experience) ? experience : undefined}
            iconName={mapCategoryToIcon(experience?.category)}
            isActive={isSelected(experience)}
            sequenceNumber={index + 1}
            onClick={() => handleOnMarkerClick(experience)}
          />
        </>
      ) : (
        <MapPinIcon
          iconName={mapCategoryToIcon(experience?.category)}
          isActive={isSelected(experience)}
          isSelected={isSelected(experience)}
          experience={experience}
          onClick={() => handleOnMarkerClick(experience)}
        />
      )}
    </AdvancedMarker>
  ));
};
