import { SpriteIcon, SpriteIconProps } from '@components/icons/SpriteIcon';
import classNames from 'classnames';
import { CardOnMap } from '../cards/CardOnMap';
import { Experience } from '@/generated';

type Props = {
  experience?: Experience;
  iconName: SpriteIconProps;
  isActive: boolean;
  sequenceNumber: number;
  onClick?: () => void;
};

export const MapItineraryIcon = ({
  experience,
  iconName,
  isActive,
  sequenceNumber,
  onClick,
}: Props) => {
  return (
    <div
      className={classNames(
        'flex justify-center items-center shadow-map gap-2 pl-1 pr-3 h-10 w-[72px] rounded-40',
        {
          'bg-black': isActive,
          'bg-gray-light': !isActive,
        },
      )}
      onClick={onClick}
    >
      <div
        className={classNames('rounded-full p-1.5', {
          'bg-white': isActive,
          'bg-black': !isActive,
        })}
      >
        <SpriteIcon
          iconName={iconName}
          className={classNames('w-5 h-5', {
            'fill-black': isActive,
            'fill-green': !isActive,
          })}
        />
      </div>
      <p
        className={classNames('text-p self-center whitespace-nowrap my-auto', {
          'text-white': isActive,
          'text-black': !isActive,
        })}
      >
        {sequenceNumber}
      </p>
      {experience && (
        <div className="hidden lg:block absolute top-[3rem] left-[-165px] w-[365px] left-0">
          <CardOnMap
            name={experience.name}
            details={experience.details}
            styles="hidden lg:flex w-[360px]"
          />
        </div>
      )}
    </div>
  );
};
