import { routes } from '@/routes/routes';
import { FB_LINK, FOOTER_COPYRIGHT_TEXT, X_LINK } from '@/utils/constants';

import { FooterIconLink } from './FooterIconLink';
import { FooterLink } from './FooterLink';
import { SpriteIcon } from '../icons/SpriteIcon';
import classNames from 'classnames';

export const Footer = ({
  withoutMargin = false,
}: {
  withoutMargin?: boolean;
}) => {
  return (
    <footer
      className={classNames(
        'grid grid-cols-1 grid-rows-3 mdm:grid-cols-4 mdm:grid-rows-2 flex-col py-5 gap-3',
        { 'mdm:mx-7': !withoutMargin },
      )}
    >
      <div className="hidden mdm:flex mdm:col-span-2 ">
        <SpriteIcon iconName="logo" className="h-[48px] w-[118px]" />
      </div>
      <div className="flex justify-center gap-2 mdm:col-span-2 mdm:justify-end">
        <FooterIconLink to={FB_LINK} iconName="facebook" />
        <FooterIconLink to={X_LINK} iconName="x" />
      </div>
      <div className="flex justify-center gap-x-7 gap-y-3 flex-wrap mdm:col-span-2 mdm:justify-start">
        <FooterLink to={routes.footer.terms}>Terms & Conditions</FooterLink>
        <FooterLink to={routes.footer.privacy}>Privacy Policy</FooterLink>
      </div>
      <p className="flex justify-center text-p-small-web text-gray mdm:col-span-2 mdm:justify-end">
        {FOOTER_COPYRIGHT_TEXT}
      </p>
    </footer>
  );
};
