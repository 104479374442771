import { useState, useRef, useMemo, useEffect } from 'react';

import { Commute, Experience } from '@/generated';
import classNames from 'classnames';
import { getUniqueDatesFromExperiences } from '@/utils/getUniqueArray';

import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { FiltersBar } from './FiltersBar';
import { useGetCategories } from '@/hooks/useGetCategories';
import { getExperiencesBySelectedDate } from '@/utils/experiencesHelperFunctions';
import { DeckGlOverlay } from '../modals/DeckGlOverlay';
import { MapMarkers } from './MapMarkers';
import { useGetElementPosition } from '@/hooks/useGetElementPosition';
import { debounce } from 'lodash';
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter';
import { PathLayer } from '@deck.gl/layers/typed';
import { convertToGeoPath } from '@/utils/convertToGeoPath';
import { BottomBar } from './BottomBar';

type Props = {
  experiences: Experience[];
  commutes: Commute[];
  filtersType?: MapFiltersType;
  chosenFilters?: string[];
  onFilterClick?: (value: string) => void;
  handleToggleFilterSheet?: () => void;
  selectedDate: string | null | undefined;
  setSelectedDate?: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >;
  rounded?: boolean;
};

type PolylineRoute = {
  id: number | undefined;
  originId: number;
  destinationId: number;
  path: number[][];
  color: number[];
};

export type MapFiltersType = 'date' | 'categories';

const DEBOUNCE_TIME = 550;

export const ExperienceMap = ({
  experiences,
  commutes,
  filtersType = 'date',
  chosenFilters,
  onFilterClick,
  handleToggleFilterSheet,
  selectedDate,
  setSelectedDate,
  rounded = true,
}: Props) => {
  /*        States         */
  const [selectedExperienceId, setSelectedExperienceId] = useState<
    number | null
  >(null);

  /*        References         */
  const itineraryItemsRef = useRef<HTMLDivElement | null>(null);
  const chosenExperienceRef = useRef<{
    [key: string]: HTMLDivElement | null;
  }>({});

  const getExperienceRef = (
    experienceId: number | undefined,
    ref: React.MutableRefObject<{
      [key: string]: HTMLDivElement | null;
    }>,
  ): HTMLDivElement | null => {
    if (!experienceId) return null;
    return ref.current[experienceId] || null;
  };

  const { getElementPosition } = useGetElementPosition(
    getExperienceRef(selectedExperienceId ?? undefined, chosenExperienceRef),
  );

  /*        Functions         */
  const filteredExperiences = experiences.filter(
    ({ details }) => details !== null,
  );

  const dateExperiences = getExperiencesBySelectedDate(
    filteredExperiences,
    selectedDate,
  );

  const { categories } = useGetCategories(filteredExperiences);

  const polylines: PolylineRoute[] = commutes.map(
    ({ id, origin_id, destination_id, polyline }) => {
      const geoPath = convertToGeoPath(polyline);
      return {
        id: id,
        originId: origin_id,
        destinationId: destination_id,
        path: geoPath,
        color: [13, 25, 34],
      };
    },
  );

  const datePolylines = polylines.filter(
    (route) =>
      dateExperiences?.find(
        (exp) => exp.id === route.originId || exp.id === route.destinationId,
      ) !== undefined,
  );

  const pathlayer = useMemo(
    () =>
      new PathLayer({
        id: 'path-layer',
        data: selectedDate ? datePolylines : [],
        pickable: true,
        widthScale: 10,
        widthMinPixels: 4,
        widthMaxPixels: 20,
        jointRounded: true,
        getPath: (d) => d.path,
        getColor: (d) => d.color,
        getWidth: () => 1,
      }),
    [datePolylines],
  );

  const mapCenter =
    dateExperiences?.find(({ details }) => details?.coordinates)?.details
      ?.coordinates || null;

  const handleMarkerClick = (experience: Experience) => {
    if (experience.id === selectedExperienceId) return;
    if (experience.id) {
      setSelectedExperienceId(experience.id);
    }
  };

  useEffect(() => {
    if (itineraryItemsRef.current && selectedExperienceId) {
      // Taking the width of the element to scroll to
      // then multiplying it by the index of the selected experience
      const pos = getElementPosition(
        getExperienceRef(selectedExperienceId, chosenExperienceRef),
      );
      if (!pos) return;
      const index = experiences.findIndex(
        (exp) => exp.id === selectedExperienceId,
      );
      if (index === -1) return;
      itineraryItemsRef.current.scrollTo({
        left: pos.width * index,
        behavior: 'smooth',
      });
    }
  }, [selectedExperienceId]);

  const selectedExperience = experiences.find(
    (exp) => exp.id === selectedExperienceId,
  );

  const filteredExperiencesByCategory = useMemo(
    () =>
      chosenFilters?.length !== 0
        ? filteredExperiences.filter((experience) =>
            experience.category?.some(({ name }) =>
              chosenFilters?.includes(capitalizeFirstLetter(name)),
            ),
          )
        : filteredExperiences,
    [chosenFilters, filteredExperiences],
  );

  const finalExperiences =
    filtersType === 'date' ? dateExperiences : filteredExperiencesByCategory;

  const handleFilterIconClick = () => {
    if (handleToggleFilterSheet) {
      handleToggleFilterSheet();
    }
  };

  const handleOnFilterClick = (value: string) => {
    if (onFilterClick) {
      onFilterClick(value);
    }
  };

  const handleItineraryExperienceOnScroll = debounce(() => {
    if (itineraryItemsRef.current) {
      finalExperiences?.some((experience) => {
        if (experience.id) {
          const experienceElement = getExperienceRef(
            experience.id,
            chosenExperienceRef,
          );
          if (isElementVisible(experienceElement)) {
            setSelectedExperienceId(experience.id);
            return true;
          }
        }
        return false;
      });
    }
  }, DEBOUNCE_TIME);

  // Utility function to check if an element is visible in the viewport
  const isElementVisible = (element: HTMLDivElement | null) => {
    if (!element) return false;
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const roundedStyles: React.CSSProperties = rounded
    ? {
        borderTopLeftRadius: '24px',
        borderTopRightRadius: '24px',
      }
    : {};

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={classNames(
        'flex flex-col bg-white w-full h-full shadow-xl gap-4 relative',
        {
          'rounded-b-3xl': rounded,
          'rounded-none': !rounded,
        },
      )}
    >
      <FiltersBar
        filtersType={filtersType}
        categories={categories}
        handleFilterIconClick={handleFilterIconClick}
        chosenFilters={chosenFilters}
        handleOnFilterClick={handleOnFilterClick}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
        dates={getUniqueDatesFromExperiences(experiences)}
      />
      <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY}>
        <Map
          mapId={import.meta.env.VITE_GOOGLE_MAP_KEY}
          zoom={12}
          center={mapCenter}
          clickableIcons={true}
          mapTypeControl={false}
          zoomControl={false}
          streetViewControl={false}
          fullscreenControl={false}
          style={{
            position: 'absolute',
            ...roundedStyles,
          }}
          onClick={() => setSelectedExperienceId(null)}
        >
          <MapMarkers
            experiences={finalExperiences ?? []}
            handleOnMarkerClick={handleMarkerClick}
            selectedExperienceId={selectedExperienceId}
            selectedDate={selectedDate}
          />
          <DeckGlOverlay layers={[pathlayer]} />
        </Map>
      </APIProvider>
      <BottomBar
        selectedDate={selectedDate}
        selectedExperienceId={selectedExperienceId}
        experiences={finalExperiences ?? []}
        chosenExperienceRef={chosenExperienceRef}
        selectedExperience={selectedExperience}
        handleOnScroll={handleItineraryExperienceOnScroll}
        ref={itineraryItemsRef}
      />
    </div>
  );
};
