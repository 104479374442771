export const queryKeyTypes = {
  address: 'address',
  commuteList: 'commute-list',
  commutes: 'commutes',
  confirmRegistrationEmail: 'confirm-registration-email',
  currentExperience: 'current-experience',
  diets: 'diets',
  experienceDetail: 'experience-detail',
  experiencesList: 'experiences-list',
  freeTimeSlots: 'free-time-slots',
  interests: 'interests',
  itinerary: 'itinerary',
  profile: 'profile',
  itinerariesList: 'itineraries-list',
  usersTrips: 'users-trips',
  resetPasswordConfirm: 'reset-password-confirm',
  experienceCategories: 'experience-categories',
  foodPreferences: 'food-preferences',
  get_user_after_verification: 'get-user-after-verification',
  blog: 'blog',
  blog_categories: 'blog-categories',
  articles: 'articles',
  latestBlogs: 'latest-blogs',
};
