import classNames from 'classnames';
import { SpriteIcon } from '@components/icons/SpriteIcon';

type ViewMoreButtonProps = {
  onClick: () => void;
  classNamesImportant?: string;
};

export const ViewMoreButton = ({
  onClick,
  classNamesImportant,
}: ViewMoreButtonProps) => {
  return (
    <button
      className={classNames(
        'flex',
        'w-full mdx:w-fit',
        'justify-center items-center gap-x-2',
        'bg-purple mdx:bg-white',
        'rounded-36 mdx:rounded-none',
        'h-[56px] mdx:h-fit',
        classNamesImportant,
      )}
      onClick={onClick}
    >
      <p
        className={classNames(
          'text-p text-[20px] leading-[27px] text-white mdx:text-purple text-nowrap',
        )}
      >
        View More
      </p>
      <div className="flex justify-between items-center rounded-360 bg-purple w-8 h-8 p-1">
        <SpriteIcon
          iconName="arrowLeft"
          className="transform rotate-180 fill-white  w-6 h-6"
        />
      </div>
    </button>
  );
};
