import React from 'react';
import { CategoryItem } from './CategoryItem';
import { BlogCategory } from '@/generated';
import { useOverflowDetection } from '@/hooks/useOverflowDetection';
import classNames from 'classnames';

export const BlogCategoriesList = ({
  categories,
  onCategoryClick,
  isFull = false,
  skipWidth = 0,
}: {
  categories: BlogCategory[];
  onCategoryClick: (category: BlogCategory) => void;
  isFull?: boolean;
  skipWidth?: number;
}) => {
  const { containerRef, visibleCount } = useOverflowDetection(
    categories,
    8,
    200,
    skipWidth,
  );

  return (
    <div
      className={classNames('flex gap-x-1 w-full grow', {
        'max-w-[75%]': !isFull,
      })}
      ref={containerRef}
    >
      {categories.slice(0, visibleCount).map((category, index) => (
        <React.Fragment key={index}>
          <CategoryItem
            title={category.name!!}
            onClick={() => onCategoryClick(category)}
            isActive={true}
            variant="small"
          />
        </React.Fragment>
      ))}
      {categories.length !== 0 && visibleCount < categories.length && (
        <CategoryItem
          title="..."
          onClick={() => {}}
          isActive={true}
          variant="small"
        />
      )}
    </div>
  );
};
type Props = {
  title: string;
  categories: BlogCategory[];
  summary?: string | null;
  created: string;
  variant?: 'default' | 'large';
  onCategoryClick: (category: BlogCategory) => void;
  isTruncateTitle?: boolean;
  isTruncateSummary?: boolean;
};

export const ArticleDescription = ({
  title,
  categories,
  summary,
  created,
  onCategoryClick,
  variant = 'default',
  isTruncateTitle = false,
  isTruncateSummary = false,
}: Props) => {
  if (variant === 'large') {
    return (
      <div className="w-full flex flex-col gap-y-4 mdx:gap-y-6">
        <BlogCategoriesList
          categories={categories}
          onCategoryClick={onCategoryClick}
          isFull
        />
        <h1 className="text-h3 text-[32px] leading-[32x] mdx:text-[60px] mdx:leading-[60px] font-[500]  ">
          {title}
        </h1>
        <p className="font-normal leading-[24px] !text-[14px] text-gray">
          {created}
        </p>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col gap-y-3">
      <div className="w-full flex justify-between items-center">
        <BlogCategoriesList
          categories={categories}
          onCategoryClick={onCategoryClick}
        />
        <p className="font-normal leading-[18.9px] text-p-web !text-[14px] text-gray">
          {created}
        </p>
      </div>
      <h3
        className={classNames('text-[24px] font-[500] leading-[32.4px]', {
          'line-clamp-2': isTruncateTitle,
        })}
      >
        {title}
      </h3>
      {summary && (
        <p
          className={classNames(
            'font-[500] leading-[21.6px] text-[16px] text-gray',
            {
              'line-clamp-2': isTruncateSummary,
            },
          )}
        >
          {summary}
        </p>
      )}
    </div>
  );
};
