import { queryKeyTypes } from "@/api/queryKeyTypes";
import { useApi } from "@/api/serviceProvider";
import { useQuery } from "@tanstack/react-query";

export const useGetLatestArticles = () => {

    const { api } = useApi();
    const { data, isLoading } = useQuery({
        queryKey: [queryKeyTypes.blog, queryKeyTypes.latestBlogs],
        queryFn: async () => {
            return await api.apiV1BlogPostsGetLastPosts(undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, 5);
        }
    });


    return {
        latestArticles: data?.results ?? [],
        isLatestArticlesLoading: isLoading
    }
};