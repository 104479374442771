import { queryKeyTypes } from "@/api/queryKeyTypes";
import { useApi } from "@/api/serviceProvider"
import { useQuery } from "@tanstack/react-query"

export const useGetArticle = (articleSlag: string | undefined) => {
    const { api } = useApi();

    const { data, isLoading, isError, error } = useQuery({
        queryKey: [queryKeyTypes.blog, articleSlag],
        queryFn: async () => {
            if (!articleSlag) return;

            const res = await api.apiV1BlogPostsSearch(
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                articleSlag,
            )
            return res;
        },
        enabled: !!articleSlag
    });

    return {
        article: data?.results?.length ? data.results[0] : undefined,
        isArticleLoading: isLoading,
        isArticleErrorCatch: isError,
        error: error ? error : (data ? (data.results ? data.results[0] : "Not found!") : "Not found!")
    }
}