import { useState } from 'react';
import { TailoringInput } from '../inputs/TailoringInput';
import { showToast } from '@/utils/showToast';
import { TailoredSearchTypes } from '@/stores/tailorSearchStore';
import { InvitationSearchTypes } from '@/stores/invitationStore';
import { MAX_SPECIFIC_THINGS_TO_INCLUDE } from '@/utils/constants';

type Params = {
  storage: TailoredSearchTypes | InvitationSearchTypes;
  setStorageSearch: (value: Partial<TailoredSearchTypes>) => void;
};

export const SpecificThingsToInclude = ({ setStorageSearch }: Params) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [specificThingsToInclude, setSpecificThingsToInclude] =
    useState<string>('');

  const updateState = (newState: string) => {
    setSpecificThingsToInclude(newState);
    setStorageSearch({ specificThingsToInclude: newState });
  };

  const handleOnAddClick = (value: string) => {
    if (!value) return;
    if (specificThingsToInclude.includes(value)) {
      showToast('This was already added');
    }
    updateState(value);
    setInputValue('');
  };

  const handleOnChange = (index: number, value: string) => {
    const newSpecificThingsToInclude = [...specificThingsToInclude];
    newSpecificThingsToInclude[index] = value;
    updateState(newSpecificThingsToInclude[0]);
  };

  const handleOnRemoveClick = (index: number) => {
    const newSpecificThingsToInclude = [...specificThingsToInclude];
    newSpecificThingsToInclude.splice(index, 1);
    updateState(newSpecificThingsToInclude[0]);
  };

  return (
    <>
      {/* {specificThingsToInclude.map((thing, index) => ( */}
      {specificThingsToInclude && (
        <TailoringInput
          placeholder=""
          value={specificThingsToInclude}
          setValue={(value) => handleOnChange(0, value)}
          handleOnRemoveClick={() => handleOnRemoveClick(0)}
          isHighlighted={true}
        />
      )}
      {specificThingsToInclude.length < MAX_SPECIFIC_THINGS_TO_INCLUDE && (
        <TailoringInput
          placeholder="Specific events or landmarks?"
          value={inputValue}
          setValue={setInputValue}
          onBlur={() => handleOnAddClick(inputValue)}
          isHighlighted={false}
        />
      )}
    </>
  );
};
