import classNames from 'classnames';
import { SpriteIcon } from '@components/icons/SpriteIcon';
import { Button } from '@components/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { routes } from '@/routes/routes';
import Cookies from 'js-cookie';
import { useBlogStore } from '@/stores/blogStore';
import { DEFAULT_BLOG_CATEGORY, DEFAULT_BLOG_TOPIC } from '@/utils/constants';

export const HeaderWithAuth = ({
  variant = 'default',
  isAbsolute = true,
}: {
  variant?: 'dark' | 'default';
  isAbsolute?: boolean;
}) => {
  const navigate = useNavigate();
  const authToken = Cookies.get('tp_access_token');
  const { setActiveCategory, setActiveTopic } = useBlogStore();
  const color = variant === 'dark' ? 'black' : 'white';

  type Destinations = 'index' | 'signIn' | 'signUp' | 'blog';
  const navTo = (dest: Destinations) => {
    switch (dest) {
      case 'index':
        navigate(authToken ? routes.myTrips : routes.home);
        break;
      case 'blog':
        navigate(routes.blog.index);
        setActiveCategory(DEFAULT_BLOG_CATEGORY);
        setActiveTopic(DEFAULT_BLOG_TOPIC);
        break;
      case 'signIn':
        navigate(routes.auth.login);
        break;
      case 'signUp':
        navigate(routes.auth.index);
        break;
    }
  };

  return (
    <header
      className={classNames('flex justify-between top-0 left-0 w-full ', {
        absolute: isAbsolute,
        relative: !isAbsolute,
      })}
    >
      <div
        className={classNames(
          'flex items-center',
          'mt-[27px] mdx:ml-[29px]',
          'ml-[16px]',
        )}
      >
        <SpriteIcon
          iconName={variant === 'dark' ? 'logo' : 'logoWhite'}
          className={classNames(
            'w-[120px] h-[46px] cursor-pointer',
            'hidden mdx:block',
            {
              'fill-white': variant === 'default',
              'fill-black': variant === 'dark',
            },
          )}
          onClick={() => {
            navTo('index');
          }}
        />
        <SpriteIcon
          iconName="logoMobile"
          className={classNames(
            'w-[114px] h-[16px] cursor-pointer',
            'block mdx:hidden',
            {
              'fill-white': variant === 'default',
              'fill-black': variant === 'dark',
            },
          )}
          onClick={() => {
            navTo('index');
          }}
        />
      </div>
      <div
        className={classNames(
          'flex items-center gap-x-6',
          'mt-[24px] mr-[16px]',
          'mdx:mt-[18px] mdx:gap-x-7',
        )}
      >
        <Button
          size="medium"
          variant="tertiary"
          onClick={() => navTo('blog')}
          styles={{
            backgroundColor: 'transparent',
            opacity: 0.7,
            padding: 0,
            color: color,
          }}
        >
          <span className={classNames({ 'text-black': variant === 'dark' })}>
            Blog
          </span>
        </Button>
        <Button
          size="medium"
          variant="tertiary"
          onClick={() => navTo('signIn')}
          styles={{
            backgroundColor: 'transparent',
            padding: 0,
            color: color,
          }}
        >
          <span className={classNames({ 'text-black': variant === 'dark' })}>
            Sign in
          </span>
        </Button>
        <div className="hidden mdx:flex">
          <Button
            size="medium"
            variant="tertiary"
            onClick={() => navTo('signUp')}
            styles={{
              border: `solid ${color} 1px`,
              backgroundColor: 'transparent',
              height: 46,
            }}
          >
            <span className={classNames({ 'text-black': variant === 'dark' })}>
              Sign up
            </span>
          </Button>
        </div>
      </div>
    </header>
  );
};
