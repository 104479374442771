import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { useApi } from '@/api/serviceProvider';
import { routes } from '@/routes/routes';
import { showToast } from '@/utils/showToast';

export const useDeleteItinerary = () => {
  const navigate = useNavigate();
  const { api } = useApi();

  const { mutate, isPending } = useMutation({
    mutationKey: ['deleteItinerary'],
    mutationFn: async (itineraryId: number | undefined) => {
      if (!itineraryId && itineraryId !== 0) {
        return;
      }

      return await api.apiV1ItinerariesDelete(itineraryId);
    },
    onSuccess: () => {
      navigate(routes.myTrips, { replace: true });
    },
    onError: () => showToast("We're sorry, we could not delete itinerary right now."),
  });

  return {
    handleDeleteItinerary: mutate,
    isLoading: isPending,
  };
};
