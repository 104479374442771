import { queryKeyTypes } from "@/api/queryKeyTypes";
import { useApi } from "@/api/serviceProvider";
import { Topic, useBlogStore } from "@/stores/blogStore";
import { DEFAULT_BLOG_CATEGORY, DEFAULT_BLOG_TOPIC, DEFAULT_PAGINATION_LIMIT, FIRST_PAGE_NUMBER } from "@/utils/constants";
import { useEffect } from "react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { BlogPost } from "@/generated";

export interface UseArticlesHook {
    articles: BlogPost[];
    isArticlesLoading: boolean;
    articlesError: unknown;
    fetchNextPage?: () => void; // Optional if needed for pagination
    hasNextPage?: boolean; // Optional if needed for pagination
}

export type useGetArticlesParams = {
    topic?: Topic;
    categoryName?: string;
    page?: number;
    pageSize?: number;
}

const getParams = ({
    categoryName,
    topic,
    page,
    pageSize = DEFAULT_PAGINATION_LIMIT,
}: useGetArticlesParams) => {
    let categoryUuid = undefined;
    let isFeatured = undefined;
    let isTravellerStory = undefined;
    let isTrending = undefined;
    let isLongRead = undefined;
    let search = undefined;

    if (topic === 'Latest Updates & Innovations') {
        isFeatured = true;
    } else if (topic === 'Long Reads') {
        isLongRead = true;
    } else if (topic === 'Trending Topics') {
        isTrending = true;
    } else if (topic === 'Traveler Stories') {
        isTravellerStory = true;
    }
    return (
        [
            categoryName !== DEFAULT_BLOG_CATEGORY.name ? categoryName : undefined,
            categoryUuid,
            `${isFeatured}`,
            `${isTravellerStory}`,
            `${isTrending}`,
            `${isLongRead}`,
            search,
            page ? page.toString() : undefined,
            pageSize.toString(),
        ]
    );
}

export const useGetHeroArticle = (props: useGetArticlesParams): UseArticlesHook => {
    const { api } = useApi();
    const parsedProps = getParams(props);

    const { data, isLoading, error } = useQuery({
        queryKey: [
            queryKeyTypes.blog,
            queryKeyTypes.articles,
            'hero',
        ],
        queryFn: async () => {
            return await api.apiV1BlogPostsList(...parsedProps);
        },
        retry: false,
    });

    return {
        articles: data?.results ?? [],
        isArticlesLoading: isLoading,
        articlesError: error,
    };
};

export const useGetLatestUpdates = (props: useGetArticlesParams): UseArticlesHook => {
    const { api } = useApi();
    const parsedProps = getParams(props);

    const { data, isLoading, error } = useQuery({
        queryKey: [
            queryKeyTypes.blog,
            queryKeyTypes.articles,
            'latest-updates-innovations',
        ],
        queryFn: async () => {
            return await api.apiV1BlogPostsList(...parsedProps);
        },
        retry: false,
    });

    return {
        articles: data?.results ?? [],
        isArticlesLoading: isLoading,
        articlesError: error,
    };
};

export const useGetTravelStoriesArticles = (props: useGetArticlesParams): UseArticlesHook => {
    const { api } = useApi();
    const parsedProps = getParams(props);

    const { data, isLoading, error } = useQuery({
        queryKey: [
            queryKeyTypes.blog,
            queryKeyTypes.articles,
            'traveler-stories',
        ],
        queryFn: async () => {
            return await api.apiV1BlogPostsList(...parsedProps);
        },
        retry: false,
    });

    return {
        articles: data?.results ?? [],
        isArticlesLoading: isLoading,
        articlesError: error,
    };
}

export const useGetLongReadsArticles = (props: useGetArticlesParams): UseArticlesHook => {
    const { api } = useApi();
    const parsedProps = getParams(props);

    const { data, isLoading, error } = useQuery({
        queryKey: [
            queryKeyTypes.blog,
            queryKeyTypes.articles,
            'long-reads',
        ],
        queryFn: async () => {
            return await api.apiV1BlogPostsList(...parsedProps);
        },
        retry: false,
    });

    return {
        articles: data?.results ?? [],
        isArticlesLoading: isLoading,
        articlesError: error,
    };
}

export const useGetTrendingTopicsArticles = (props: useGetArticlesParams): UseArticlesHook => {
    const { api } = useApi();
    const parsedProps = getParams(props);

    const { data, isLoading, error } = useQuery({
        queryKey: [
            queryKeyTypes.blog,
            queryKeyTypes.articles,
            'trending-topics',
        ],
        queryFn: async () => {
            return await api.apiV1BlogPostsList(...parsedProps);
        },
        retry: false,
    });

    return {
        articles: data?.results ?? [],
        isArticlesLoading: isLoading,
        articlesError: error,
    };
};

export const useGetArticles = (props: useGetArticlesParams): UseArticlesHook => {
    const { api } = useApi();
    const parsedProps = getParams(props);
    const { pagination, setPagination } = useBlogStore();

    const calculatePageCount = (itemCount: number, pageSize: number) => {
        return Math.ceil(itemCount / pageSize);
    };
    const extractCurrentPage = (previousUrl?: string | null, nextUrl?: string | null) => {
        if (previousUrl) {
            return parseInt(previousUrl.split('page=')[1] + 1);
        } else if (nextUrl) {
            return parseInt(nextUrl.split('page=')[1]) - 1;
        } else {
            return 1;
        }
    };

    const { data, isLoading, error } = useQuery({
        queryKey: [
            queryKeyTypes.blog,
            queryKeyTypes.articles,
            ['list', props.categoryName ?? DEFAULT_BLOG_CATEGORY.name!!, props.topic ?? DEFAULT_BLOG_TOPIC.title!!, props.page],
        ],
        queryFn: async () => {

            const res = await api.apiV1BlogPostsList(...parsedProps);
            if (!pagination) {
                setPagination({
                    category: props.categoryName ?? DEFAULT_BLOG_CATEGORY.name!!,
                    topic: props.topic ?? DEFAULT_BLOG_TOPIC.title!!,
                    page: extractCurrentPage(res.previous, res.next),
                    pageSize: props.pageSize ?? DEFAULT_PAGINATION_LIMIT,
                    pageCount: calculatePageCount(res.count, props.pageSize ?? DEFAULT_PAGINATION_LIMIT),
                })
            }
            return res;
        },
        retry: false,
        enabled: !!props.page,
    });

    return {
        articles: data?.results ?? [],
        isArticlesLoading: isLoading,
        articlesError: error,
    };
};


export const useGetInfinityArticles = ({
    topic,
    categoryName,
    pageSize = DEFAULT_PAGINATION_LIMIT,
}: useGetArticlesParams): UseArticlesHook => {
    const { api } = useApi();
    const { data, fetchNextPage, hasNextPage, isLoading, error, refetch } = useInfiniteQuery({
        queryKey: [
            queryKeyTypes.blog,
            queryKeyTypes.articles,
            ['list', categoryName ?? DEFAULT_BLOG_CATEGORY.name!!, topic ?? DEFAULT_BLOG_TOPIC.title!!],
        ],
        queryFn: async ({
            pageParam = FIRST_PAGE_NUMBER,
        }) => {
            return await api.apiV1BlogPostsList(...getParams({ categoryName, topic, pageSize, page: pageParam }));
        },
        getNextPageParam: (lastPage, allPages) =>
            lastPage.next ? allPages.length + 1 : undefined,
        initialPageParam: FIRST_PAGE_NUMBER,
        retry: false,
    });

    useEffect(() => {
        refetch();
    }, [topic, categoryName, pageSize]);

    const articles = data?.pages.map(({ results }) => results).flat(1);

    return {
        articles: articles ?? [],
        fetchNextPage,
        hasNextPage,
        isArticlesLoading: isLoading,
        articlesError: error,
    }
};