import { BlogCategory } from '@/generated';
import { BlogCategoriesList } from './ArticleDescription';
import { ArticleItem } from './ArticleItem';
import { useGetLatestArticles } from '@/hooks/blog/useGetLatestArticles';

type Props = {
  categories: BlogCategory[];
  onCategoryClick: (category: BlogCategory) => void;
  onArticleClick: (articleSlug: string) => void;
};

export const LatestArticles = ({
  categories,
  onCategoryClick,
  onArticleClick,
}: Props) => {
  const { latestArticles, isLatestArticlesLoading } = useGetLatestArticles();

  if (
    !isLatestArticlesLoading &&
    ((latestArticles && latestArticles.length === 0) || !latestArticles)
  )
    return null;

  return (
    <div className="flex flex-col gap-y-8 max-w-[468px] min-w-[300px]">
      <div className="flex flex-col mdx:px-6 gap-y-2">
        <p className="text-[40px] leading-[40px] font-[700]">The Latest</p>
        <div className="flex items-center gap-x-2">
          <p className="text-p-web font-normal leading-[23.68px] text-nowrap">
            View More From:
          </p>
          <BlogCategoriesList
            categories={categories}
            onCategoryClick={onCategoryClick}
            skipWidth={0}
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-1">
        {latestArticles?.map((article, index) => (
          <ArticleItem
            key={index}
            {...article}
            variant="title-only"
            onCategoryClick={onCategoryClick}
            onArticleClick={() => onArticleClick(article.slug)}
          />
        ))}
      </div>
    </div>
  );
};
