import classNames from 'classnames';

type Props = {
  title: string;
  onClick: () => void;
  isActive: boolean;
  variant?: 'default' | 'small';
};

export const CategoryItem = ({
  title,
  onClick,
  isActive,
  variant = 'default',
}: Props) => {
  const height = 'default' === variant ? 'h-[48px]' : 'h-[30px]';
  const padding =
    'default' === variant ? 'px-5 py-[13px] mdx:px-6 mdx:py-3' : 'px-3 py-1';
  const textStyles =
    'default' === variant
      ? 'mdx:text-[18px] leading-[20px] mdx:leading-[24.3px]'
      : 'font-[500] leading-[21.6px]';

  return (
    <button
      className={classNames(height, padding, 'cursor-pointer rounded-[62px]', {
        'bg-purple bg-opacity-10 text-purple': isActive,
      })}
      onClick={onClick}
    >
      <p
        className={classNames(
          'text-accent text-nowrap text-center align-center box',
          'text-[16px]',
          'tracking-normal',
          textStyles,
        )}
      >
        {title}
      </p>
    </button>
  );
};
