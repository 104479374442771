import {
  ImageContainer,
  convertBlogPostToImageSrcSet,
} from '@/components/containers/ImageContainer';
import { getFormattedDate } from '@/utils/formatDateTime';
import { DATE_DAY_MONTH_YEAR_FORMAT } from '@/utils/constants';
import classNames from 'classnames';
import { ArticleDescription } from './ArticleDescription';
import { BlogCategory, BlogPost } from '@/generated';
import blogPlaceholder from '@assets/images/blog/placeholder.webp';
import { ArticleItemLoadingPlaceholder } from './ArticleItemLoadingPlaceholder';

type Props = BlogPost & {
  variant?: 'default' | 'title-only';
  direction?: 'row' | 'column';
  onCategoryClick: (category: BlogCategory) => void;
  onArticleClick?: () => void;
  divStyles?: string;
  isLoading?: boolean;
};

export const ArticleItem = ({
  title,
  categories,
  summary,
  created,
  variant = 'default',
  direction = 'column',
  onCategoryClick,
  divStyles,
  isLoading,
  onArticleClick,
  ...props
}: Props) => {
  const imageSrcSet = convertBlogPostToImageSrcSet(props as BlogPost);

  if (isLoading) {
    return (
      <ArticleItemLoadingPlaceholder
        variant={variant}
        direction={direction}
        divStyles={divStyles}
      />
    );
  }

  if (variant === 'title-only') {
    return (
      <div
        className="flex bg-gray-light opacity-80 w-full rounded-24 p-6 cursor-pointer"
        onClick={onArticleClick}
      >
        <h3 className="text-[20px] font-[500] leading-[27px]">{title}</h3>
      </div>
    );
  }

  if (direction === 'row') {
    return (
      <div
        className={classNames(
          'flex w-full h-full gap-x-5 min-w-[359px] cursor-pointer',
          divStyles,
        )}
        onClick={onArticleClick}
      >
        <div className="relative flex w-full h-full grow flex-col overflow-hidden self-stretch relative flex aspect-[1.5] w-full rounded-t-3xl">
          <ImageContainer
            image={imageSrcSet}
            placeholderImg={blogPlaceholder}
            alt="blog"
            overrideStylesImage="w-full h-full object-cover rounded-32 min-w-[200px]"
            displayImage={true}
          />
        </div>
        <div className="flex flex-col h-full w-full gap-x-5">
          <ArticleDescription
            title={title}
            categories={categories ?? []}
            summary={summary!!}
            created={getFormattedDate({
              date: new Date(created!!),
              format: DATE_DAY_MONTH_YEAR_FORMAT,
            })}
            onCategoryClick={onCategoryClick}
            isTruncateTitle
            isTruncateSummary
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'flex flex-col w-full h-full gap-y-6 gap-x-6 min-w-[250px] cursor-pointer',
        divStyles,
      )}
      onClick={onArticleClick}
    >
      <div className="relative flex w-full h-full grow flex-col overflow-hidden self-stretch relative flex aspect-[1.5] w-full rounded-t-3xl">
        <ImageContainer
          image={imageSrcSet}
          placeholderImg={blogPlaceholder}
          alt="blog"
          overrideStylesImage="object-cover rounded-32 relative max-h-[480px]"
          displayImage={true}
        />
      </div>
      <ArticleDescription
        title={title}
        categories={categories ?? []}
        summary={summary!!}
        created={getFormattedDate({
          date: new Date(created!!),
          format: DATE_DAY_MONTH_YEAR_FORMAT,
        })}
        onCategoryClick={onCategoryClick}
        isTruncateTitle
        isTruncateSummary
      />
    </div>
  );
};
