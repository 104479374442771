import { Button } from '@/components/buttons/Button';
import { SpriteIcon } from '@/components/icons/SpriteIcon';
import { routes } from '@/routes/routes';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import classNames from 'classnames';

export const NotFoundPage = ({ returnToThe }: { returnToThe?: string }) => {
  const navigate = useNavigate();
  const authCookie = Cookies.get('tp_access_token');

  const handleNavigateButt = () => {
    if (returnToThe) {
      navigate(returnToThe, { replace: true });
      return;
    }
    if (authCookie) {
      navigate(routes.myTrips, { replace: true });
    } else {
      navigate(routes.home, { replace: true });
    }
  };
  return (
    <div className="grid gap-y-8 h-full mt-32 mx-3 justify-center">
      <div className="flex justify-center align-middle">
        <SpriteIcon
          iconName="logo"
          className="w-full h-15 stroke-black fill-black"
        />
      </div>
      <div
        className={classNames(
          'flex flex-col pl-5 pr-5 pt-6 pb-6',
          'background bg-purple text-white rounded-36 gap-y-6',
          'max-w-[420px]',
        )}
      >
        <h1 className="text-h3 text-center">Page not found</h1>
        <p className="text-p text-center">
          The Page you are looking for doesn't exist or an other error occurred.
        </p>
        <Button
          size="large"
          variant="primary"
          type="button"
          full={true}
          iconName="stars"
          onClick={handleNavigateButt}
        >
          {returnToThe
            ? 'Get back'
            : authCookie
            ? 'Get back to My Trips page!'
            : 'Get back to Home page!'}
        </Button>
      </div>
    </div>
  );
};
