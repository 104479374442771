import { debounce } from 'lodash';
import { useEffect, useState, useRef } from 'react';

/**
 * Custom hook to detect if the container overflows with items and adjusts
 * the number of visible items accordingly.
 *
 * @param {any[]} items - The array of items to be rendered inside the container.
 * @param {number} [delay=200] - Optional debounce delay in milliseconds for resizing events.
 * 
 * @returns {{ containerRef: React.RefObject<HTMLDivElement>, visibleCount: number }} 
 * - `containerRef`: A ref for the container to observe for overflow.
 * - `visibleCount`: The number of items that fit within the container before overflow.
 */
export const useOverflowDetection = (items: any[], gap: number = 8, delay: number = 200, skipWidth: number = 0) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [visibleCount, setVisibleCount] = useState(items.length);

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        const measureOverflow = debounce(() => {
            let totalWidth = 0;
            let visibleItems = items.length;

            Array.from(container.children).forEach((child, index) => {
                totalWidth += (child as HTMLElement).offsetWidth + gap + skipWidth;
                if (totalWidth > container.clientWidth && index < visibleItems) {
                    visibleItems = index;
                }
            });

            if (visibleItems === visibleCount) return;
            setVisibleCount(visibleItems);
        }, delay);

        const resizeObserver = new ResizeObserver(measureOverflow);
        if (container) {
            resizeObserver.observe(container);
        }

        // Initial check
        measureOverflow();

        return () => {
            resizeObserver.disconnect();
        };
    }, [items]);

    return { containerRef, visibleCount };
};
