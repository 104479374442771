import { SpriteIcon } from '@/components/icons/SpriteIcon';
import classNames from 'classnames';

export type Direction = 'left' | 'right';
type Props = {
  isVisible: boolean;
  onClick: (direction: Direction) => void;
  direction: Direction;
};
export const SliderButton = ({ isVisible, onClick, direction }: Props) => {
  const gradient =
    direction === 'left'
      ? 'bg-gradient-to-r from-white to-transparent'
      : 'bg-gradient-to-l from-white to-transparent';

  const iconStyles = direction === 'left' ? '' : 'transform rotate-180';
  const position = direction === 'left' ? 'left-0' : 'right-0';
  return (
    <>
      <span className={classNames('hidden', { 'mdx:flex': isVisible })}>
        <span
          className={classNames(
            'absolute top-0',
            position,
            'w-[180px] h-full',
            gradient,
            'z-9',
          )}
        />
        <div
          className={classNames(
            'absolute top-0',
            position,
            'w-[48px] h-full',
            'flex items-center justify-center',
            'bg-purple rounded-360',
            'z-10',
          )}
        >
          <SpriteIcon
            iconName="chevron_left"
            className={`w-6 h-6 fill-white ${iconStyles}`}
            onClick={() => {
              onClick(direction);
            }}
          />
        </div>
      </span>
    </>
  );
};
