import { ExperienceFilterList } from '@components/lists/ExperienceFilterList';
import { MapFiltersType } from '@components/map/ExperienceMap';
import { FilterItemButton } from '@components/buttons/FilterItemButton';
import { IconButton } from '@components/buttons/IconButton';
import { DayButton } from '@components/buttons/DayButton';

type Props = {
  filtersType: MapFiltersType;
  categories?: string[];
  handleFilterIconClick: () => void;
  chosenFilters?: string[];
  handleOnFilterClick: (value: string) => void;
  selectedDate: string | null | undefined;
  setSelectedDate?: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >;
  dates?: string[];
};

export const FiltersBar = ({
  filtersType,
  categories = [],
  handleFilterIconClick,
  chosenFilters,
  handleOnFilterClick,
  setSelectedDate,
  selectedDate,
  dates = [],
}: Props) => {
  return (
    <div className="sticky top-0 z-20">
      <div className="w-full overflow-x-auto flex pt-5 pl-2 gap-x-2 scrollbar-hide lg:hidden">
        {filtersType === 'categories' && (
          <>
            <IconButton
              iconName="filter"
              background="gray-light"
              onClick={handleFilterIconClick}
            />
            <ExperienceFilterList
              filters={categories}
              chosenFilters={chosenFilters ?? []}
              onFilterClick={handleOnFilterClick}
            />
          </>
        )}
        {filtersType === 'date' && (
          <>
            <FilterItemButton
              onClick={() => (setSelectedDate ? setSelectedDate(null) : null)}
              selected={!selectedDate}
            >
              All experiences
            </FilterItemButton>
            {dates.map((date) => (
              <DayButton
                key={date}
                onClick={() =>
                  setSelectedDate ? setSelectedDate(date as string) : null
                }
                selected={selectedDate === date}
                date={date ?? ''}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
