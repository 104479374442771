import { useState } from 'react';
import { SliderButton } from './SliderButton';
import { CategoryItem } from './CategoryItem';
import { BlogCategory } from '@/generated';

type Props = {
  categories: BlogCategory[];
  isLoading: boolean;
  activeCategory: BlogCategory;
  handleOnCategoryClick: (category: BlogCategory) => void;
};
export const CategorySlider = ({
  categories,
  isLoading,
  activeCategory,
  handleOnCategoryClick,
}: Props) => {
  const [isLeftButtonVisible, setIsLeftButtonVisible] =
    useState<boolean>(false);
  const [isRightButtonVisible, setIsRightButtonVisible] =
    useState<boolean>(true);

  const handleOnArrowClick = (direction: 'left' | 'right') => {
    if (direction === 'left') {
      // handle left arrow click
    }
    if (direction === 'right') {
      // handle right arrow click
    }
  };

  const handleOnScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget;
    const scrollLeft = target.scrollLeft;
    const scrollableWidth = target.scrollWidth - target.clientWidth;

    setIsLeftButtonVisible(scrollLeft > 0);
    setIsRightButtonVisible(scrollLeft < scrollableWidth - 1);
  };

  return (
    <div className="relative w-full flex h-[48px]">
      <SliderButton
        isVisible={isLeftButtonVisible}
        direction={'left'}
        onClick={handleOnArrowClick}
      />
      <div
        className="flex max-w-screen-lgx overflow-x-auto hide-scrollbar mdx:gap-x-2 h-full"
        onScroll={handleOnScroll}
      >
        {!isLoading &&
          categories.map((category, index) => (
            <CategoryItem
              key={index}
              title={category.name!!}
              onClick={() => handleOnCategoryClick(category)}
              isActive={activeCategory.name === category.name}
            />
          ))}
        {isLoading && (
          <>
            {[...Array(10)].map((_, i) => (
              <div
                key={i}
                className="h-[48px] min-w-[150px] animate-pulse bg-gray-medium rounded-[62px]"
              />
            ))}
          </>
        )}
      </div>
      <SliderButton
        isVisible={isRightButtonVisible}
        direction={'right'}
        onClick={handleOnArrowClick}
      />
    </div>
  );
};
