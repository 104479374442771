import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { AppRouter } from './routes/AppRouter';
import ReactGA from 'react-ga4';
import { useLoadPlacesLibrary } from './hooks/useLoadPlacesLibrary';

const App = () => {
  const queryClient = new QueryClient();

  // initialize Google Analytics
  ReactGA.initialize(import.meta.env.VITE_GTM);

  useLoadPlacesLibrary();

  return (
    <QueryClientProvider client={queryClient}>
      <AppRouter />
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default App;
