import { IconButton } from '@components/buttons/IconButton';
import { SpriteIcon } from '@components/icons/SpriteIcon';
import { forwardRef } from 'react';
import { ImageContainer } from '../containers/ImageContainer';

type Props = {
  // The type "any" for the reference is used on purpose - we pass "(card: Element) => void" type to this prop in ExperienceList
  // eslint-disable-next-line @/typescript-eslint/no-explicit-any
  reference?: any;
  title: string | null | undefined;
  rating: number | null | undefined;
  reviews: number | null | undefined;
  image: string;
  priceLevel: number;
  onAddClick?: () => void;
};

export const CatalogueCard = forwardRef<HTMLButtonElement | null, Props>(
  (
    { reference, title, rating, reviews, image, priceLevel, onAddClick },
    ref,
  ) => {
    return (
      <div className="relative w-44 shrink-0" ref={reference}>
        <div className="h-[140px] relative">
          <ImageContainer
            image={image}
            overrideStylesImage="rounded-t-3xl h-full w-full object-cover"
            overrideStylesLoadingDiv="rounded-none rounded-t-3xl h-full w-full"
            alt={title ?? 'Name unknown'}
            displayImage={true}
          />
        </div>
        {onAddClick && (
          <div className="flex absolute right-3 top-3">
            <IconButton
              iconName="plus"
              size="small"
              onClick={onAddClick}
              ref={ref}
            />
          </div>
        )}
        <div className="bg-white shadow-general flex w-full min-h-[140px] flex-col px-3 pt-2 pb-3 rounded-b-3xl">
          <div className="flex flex-col gap-1">
            <div className="items-center flex gap-1">
              <SpriteIcon iconName="star" className="w-5 h-5" />
              <div className="flex text-p-medium gap-1">
                <span className="text-black">({rating ?? 0})</span>
                <span className="text-gray">({reviews ?? 0})</span>
              </div>
            </div>
            <p className="text-p-bold text-black">{title ?? 'Unknown'}</p>
            <div className="text-p-medium flex">
              {Array(5)
                .fill(null)
                .map((_, index) => (
                  <div
                    key={index}
                    className={` ${
                      index < priceLevel ? 'text-black' : 'text-gray'
                    }`}
                  >
                    $
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  },
);
