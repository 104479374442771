import { Footer } from '@components/footer/Footer';
import { FeatureList } from '@components/lists/FeatureList';
import { ItineraryLoader } from '@components/loaders/ItineraryLoader';
import { useInitialSearchStore } from '@stores/initialSearchStore';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGenerateItinerary } from '@/hooks/useGenerateItinerary';
import { routes } from '@/routes/routes';
import { useTailorItinerary } from '@/hooks/useTailorItinerary';
import { useUpdateItinerary } from '@/hooks/useUpdateItinerary';
import { useSendInvites } from '@/hooks/useSendInvites';
import { useInvitationLink } from '@/hooks/useInvitationLink';
import { useNextStepStore } from '@/stores/nextStepStore';
import { HeroSection } from '@/components/landing/HeroSection';
import { InterestSection } from '@/components/landing/InterestSection';
import { useStartPageModalStore } from '@/stores/startPageModalStore';

export const StartPage = () => {
  const dateRange = useInitialSearchStore((state) => state.dateRange);
  const { setOpenedModal } = useStartPageModalStore();
  const navigate = useNavigate();

  const handleSignInClick = (type: 'signIn' | 'signUp' | 'index') => {
    if (type === 'index') {
      navigate(routes.auth.index);
    } else if (type === 'signIn') {
      navigate(routes.auth.login);
    } else if (type === 'signUp') {
      navigate(routes.auth.index);
    }
  };

  const authCookie = Cookies.get('tp_access_token');

  const { itineraryId, invitedPeople, isWaitingForPeople } =
    useInitialSearchStore((state) => state);
  const setInitialSearch = useInitialSearchStore(
    (state) => state.setInitialSearch,
  );
  const { tailorItinerary, isPending: isTailoring } = useTailorItinerary({
    onClose: () => {
      navigateToItinerary(invitationRedirectLink);
    },
    itineraryId: itineraryId ?? undefined,
    toastMessage: 'Generated itinerary',
    isGAEventNeeded: false,
  });

  const { isPending, generateItinerary } = useGenerateItinerary({});
  const { updateItinerary, isLoading: isItineraryUpdating } =
    useUpdateItinerary();
  const { sendInvites } = useSendInvites(itineraryId ? itineraryId : undefined);
  const { invitationRedirectLink, navigateToItinerary } = useInvitationLink(
    itineraryId ?? undefined,
    '',
  );
  const setNextStep = useNextStepStore((state) => state.setNextStep);
  const handleGenerateItinerary = () => {
    if (invitedPeople && !authCookie) {
      setNextStep({
        saveTripAdmin: {
          itineraryId: itineraryId ?? undefined,
          peopleToInvite: invitedPeople ?? [],
        },
      });
      setInitialSearch({
        itineraryId: null,
        invitedPeople: null,
        isWaitingForPeople: false,
      });
      navigate(routes.auth.index);
      return;
    }

    if (itineraryId) {
      updateItinerary(itineraryId);
      sendInvites({ invitedUsers: invitedPeople ?? [] });

      if (isWaitingForPeople) {
        setInitialSearch({
          itineraryId: null,
          invitedPeople: null,
          isWaitingForPeople: false,
        });
        navigateToItinerary(invitationRedirectLink);
      } else {
        tailorItinerary([]);
        setInitialSearch({
          itineraryId: null,
          invitedPeople: null,
          isWaitingForPeople: false,
        });
      }
    } else {
      generateItinerary();
      setInitialSearch({
        itineraryId: null,
        invitedPeople: null,
        isWaitingForPeople: false,
      });
    }
  };

  useEffect(() => {
    setOpenedModal('NONE');
    setInitialSearch({
      invitedPeople: null,
      isWaitingForPeople: false,
      itineraryId: null,
    });
  }, []);

  if (isPending || isTailoring || isItineraryUpdating) {
    return <ItineraryLoader dateRange={dateRange} />;
  }

  return (
    <>
      <div className="flex flex-col w-screen justify-center my-0 mx-auto max-w-[1440px] overflow-hidden relative">
        <HeroSection
          onGenerateItinerary={handleGenerateItinerary}
          isLoading={isPending}
          onSignIn={handleSignInClick}
        />
        <InterestSection />
        <FeatureList />
        <Footer />
      </div>
    </>
  );
};
