import { ItineraryStatusTab } from '@/components/headers/MyTripsHeader';
import { Itinerary } from '@/generated';
import { queryKeyTypes } from '@api/queryKeyTypes';
import { useApi } from '@api/serviceProvider';
import { useQuery } from '@tanstack/react-query';

export const useGetUsersTrips = (chosenTab: ItineraryStatusTab) => {
  const apiFactory = useApi();

  // FIXME: Need to assign correct status to the itineraries, so we don't need that filtering
  const filterTrips = (trips: Itinerary[], chosenTab: ItineraryStatusTab) => {
    const now = new Date().toISOString();

    if (chosenTab === 'all' || chosenTab === 'pending') {
      return trips;
    } else if (chosenTab === 'ongoing') {
      return trips.filter((trip) => {
        return trip.end_date >= now;
      });
    } else if (chosenTab === 'finished') {
      return trips.filter((trip) => {
        return trip.end_date < now;
      });
    } else {
      return trips;
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: [queryKeyTypes.usersTrips, chosenTab],
    queryFn: async () => {
      const trips = await apiFactory.api.apiV1UsersMyTrips(chosenTab === 'finished' ? 'all' : chosenTab as string);
      return filterTrips(trips, chosenTab);
    },
    retry: 1,
  });

  return {
    usersTrips: data,
    isUsersTripsLoading: isLoading,
  };
};
