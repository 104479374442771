import { BlogCategory, BlogPost } from '@/generated';
import {
  UseArticlesHook,
  useGetArticlesParams,
} from '@/hooks/blog/useGetArticles';
import { TopicType, useBlogStore } from '@/stores/blogStore';
import classNames from 'classnames';
import { ArticleItem } from './ArticleItem';
import { SpriteIcon } from '@/components/icons/SpriteIcon';
import { Pagination } from './Pagination';
import { DEFAULT_BLOG_CATEGORY, DEFAULT_BLOG_TOPIC } from '@/utils/constants';
import { useScrollToTheTop } from '@/hooks/useScrollToTheTop';

type Props = {
  header?: string;
  topic?: TopicType;
  activeCategory?: BlogCategory;
  onCategoryClick: (category: BlogCategory) => void;
  onArticleClick: (articleSlug: string) => void;
  pageSize?: number;
  isWithPagination?: boolean;
  useDataHook: (props: useGetArticlesParams) => UseArticlesHook;
};

export const ArticlesList = ({
  header,
  topic,
  activeCategory,
  onCategoryClick,
  onArticleClick,
  pageSize = 5,
  isWithPagination = false,
  useDataHook,
}: Props) => {
  const { pagination, setPagination } = useBlogStore();
  const { scrollToItemRef, scrollToTheTop } = useScrollToTheTop();
  const { articles, isArticlesLoading, hasNextPage, fetchNextPage } =
    useDataHook({
      topic: topic?.title ?? DEFAULT_BLOG_TOPIC.title!!,
      categoryName: activeCategory?.name ?? DEFAULT_BLOG_CATEGORY.name!!,
      page: pagination?.page ?? 1,
      pageSize: pagination?.pageSize ?? pageSize,
    });

  if (!isArticlesLoading && !articles.length) {
    return null;
  }

  const renderLoadingPlaceholder = () => (
    <div className="flex flex-col gap-y-8 gap-y-[48px] mb-[60px] mdx:mb-[155px]">
      {header && (
        <div className="flex justify-between items-center px-2 mdx:px-7">
          <div
            className={classNames(
              'h-[32px] mdxh-[60px] w-[40%]',
              'animate-pulse bg-gray rounded-32',
            )}
          ></div>
        </div>
      )}
      <div className={classNames('flex flex-col', 'gap-y-8 mdx:gap-y-[64px]')}>
        <div className="flex flex-col mdx:flex-row gap-x-5 gap-y-8 px-2 mdx:px-7">
          {[...Array(2)].map((article, index) => (
            <ArticleItem
              key={index}
              {...article}
              onCategoryClick={onCategoryClick}
              onArticleClick={() => onArticleClick(article.slug)}
              isLoading={true}
            />
          ))}
        </div>
        {renderList([...Array(6)])}
      </div>
    </div>
  );

  const renderList = (articles: BlogPost[], step: number = 3) => {
    const list = [];

    for (let i = 0; i < articles.length; i += step) {
      list.push(articles.slice(i, i + step));
    }
    return list.map((articles, index) => (
      <div
        className="flex gap-x-2 mdx:gap-x-5 overflow-auto scrollbar-hide"
        key={index}
      >
        {articles.map((article, index) => (
          <ArticleItem
            key={index}
            {...article}
            onCategoryClick={onCategoryClick}
            onArticleClick={() => onArticleClick(article.slug)}
            divStyles={classNames({
              'ml-2 mdx:ml-7': index === 0,
              'mr-2 mdx:mr-7': index === articles.length - 1,
            })}
            isLoading={isArticlesLoading}
          />
        ))}
      </div>
    ));
  };

  return (
    <>
      {isArticlesLoading && renderLoadingPlaceholder()}
      {!isArticlesLoading && (
        <div
          className="flex flex-col gap-y-8 gap-y-[48px] mb-[60px] mdx:mb-[155px]"
          ref={scrollToItemRef}
        >
          {header && (
            <div className="flex justify-between items-center px-2 mdx:px-7">
              <h4
                className={classNames(
                  'text-h3 uppercase font-[500] text-[32px] leading-[32px] tracking-[-0.04em]',
                  'mdx:text-[60px] mdx:leading-[60px]',
                )}
              >
                {header}
              </h4>
            </div>
          )}
          <div
            className={classNames('flex flex-col', 'gap-y-8 mdx:gap-y-[64px]')}
          >
            <div className="flex flex-col mdx:flex-row gap-x-5 gap-y-8 px-2 mdx:px-7">
              {articles.splice(0, 2).map((article, index) => (
                <ArticleItem
                  key={index}
                  {...article}
                  onCategoryClick={onCategoryClick}
                  onArticleClick={() => onArticleClick(article.slug)}
                />
              ))}
            </div>
            {renderList(articles)}
            {!isWithPagination && hasNextPage && (
              <div className="flex justify-center w-full px-2 mdx:px-7">
                <button
                  onClick={() => (fetchNextPage ? fetchNextPage() : null)}
                  className="flex items-center justify-center bg-purple text-white rounded-48 w-full mdx:w-fit gap-x-2 px-[41.5px] py-6 mdx:h-[72px] h-[56px]"
                  disabled={isArticlesLoading}
                >
                  <SpriteIcon
                    iconName="hearth"
                    className="w-6 h-6 fill-white"
                  />
                  <p className="text-p">Explore more</p>
                </button>
              </div>
            )}
            {isWithPagination && pagination && (
              <Pagination
                componentName="BlogPagination"
                boundaryCount={0}
                count={pagination.pageCount}
                disabled={false}
                hideNextButton={false}
                hidePrevButton={true}
                showFirstButton={false}
                showLastButton={false}
                siblingCount={2}
                onChange={(_, page) => {
                  setPagination({
                    ...pagination,
                    page: page,
                  });
                  scrollToTheTop();
                }}
                page={pagination.page}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
