import { SpriteIcon, SpriteIconProps } from '@components/icons/SpriteIcon';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';

type Props = {
  icon?: SpriteIconProps;
  children: ReactNode;
  onClick: () => void;
  isActive?: boolean;
  styles?: string;
};

export const TabBarButton = ({
  icon,
  children,
  isActive,
  onClick,
  styles,
}: Props) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        'relative flex z-10 px-2 py-3.5 w-full rounded-full justify-center disabled:bg-gray-disabled h-fit',
        {
          'text-purple fill-purple hover:bg-purple-light hover:text-purple hover:fill-purple':
            isActive,
          'text-black': !isActive,
        },
        styles,
      )}
    >
      {isActive && (
        <motion.div
          layoutId="active-pill"
          className="absolute inset-0 bg-purple-light rounded-full"
        />
      )}
      <span className="relative flex gap-2">
        {icon && <SpriteIcon iconName={icon} className="w-6 h-6 " />}
        <p className="self-center text-p-medium whitespace-nowrap">
          {children}
        </p>
      </span>
    </button>
  );
};
