import { BlogCategory } from '@/generated';
import {
  UseArticlesHook,
  useGetArticlesParams,
  useGetHeroArticle,
  useGetLatestUpdates,
  useGetLongReadsArticles,
  useGetTravelStoriesArticles,
  useGetTrendingTopicsArticles,
} from '@/hooks/blog/useGetArticles';
import { create, StateCreator } from 'zustand';
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';

export type Topic =
  | 'Our Blog'
  | 'Latest Updates & Innovations'
  | 'Long Reads'
  | 'Traveler Stories'
  | 'Trending Topics';

export type TopicType = {
  title: Topic;
  slug: string;
  useDataHook?: (props: useGetArticlesParams) => UseArticlesHook;
};
type PaginationItem = {
  category: string; // active category
  topic: string; // active topic
  page: number; // page number
  pageSize: number; // number of articles per page
  pageCount: number; // number of page
};

export const topics: TopicType[] = [
  { title: 'Our Blog', slug: 'home', useDataHook: useGetHeroArticle },
  {
    title: 'Latest Updates & Innovations',
    slug: 'latest-updates-innovations',
    useDataHook: useGetLatestUpdates,
  },
  {
    title: 'Long Reads',
    slug: 'long-reads',
    useDataHook: useGetLongReadsArticles,
  },
  {
    title: 'Traveler Stories',
    slug: 'traveler-stories',
    useDataHook: useGetTravelStoriesArticles,
  },
  {
    title: 'Trending Topics',
    slug: 'trending-topics',
    useDataHook: useGetTrendingTopicsArticles,
  },
];

type State = {
  activeCategory: BlogCategory;
  activeTopic: TopicType;
  pagination?: PaginationItem;
};

type Actions = {
  setActiveCategory: (category: BlogCategory) => void;
  setActiveTopic: (topic: TopicType) => void;
  setPagination: (pagination?: PaginationItem) => void;
  resetStore: () => void;
};

const initialState: State = {
  activeCategory: {
    name: 'All Categories',
    uuid: undefined,
    slug: 'all-categories',
  },
  activeTopic: topics[0],
  pagination: undefined,
};

type BlogStoreType = State & Actions;

type BlogPersistStoreType = (
  config: StateCreator<BlogStoreType>,
  options: PersistOptions<BlogStoreType>,
) => StateCreator<BlogStoreType>;

export const useBlogStore = create<BlogStoreType>(
  (persist as BlogPersistStoreType)(
    (set) => ({
      ...initialState,
      setActiveCategory: (selectedCategory: BlogCategory) =>
        set({ activeCategory: selectedCategory }),
      setActiveTopic: (selectedTopic) => set({ activeTopic: selectedTopic }),
      setPagination: (pagination) => set({ pagination }),
      resetStore: () => set(initialState),
    }),
    {
      name: 'blog-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
