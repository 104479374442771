import { Experience } from '@/generated';

export const getUniqueDatesFromExperiences = (
  experiences: Experience[],
): string[] => {
  return [
    ...new Set(
      experiences
        .map(({ date }) => date)
        .filter((date): date is string => date !== null && date !== undefined),
    ),
  ].sort();
};

export const getUniqueStrings = (values: string[]) =>
  values?.filter(
    (value, index) => index === values?.findIndex((val) => value === val),
  );
