export const routes = {
  home: '/',
  hiddenHome: '/hidden-home',
  profile: '/profile',
  myTrips: '/my-trips',
  itinerary: {
    index: '/itinerary/:id/:token?',
    addExperience: '/itinerary/:id/add-experience',
    search: '/itinerary/:id/add-experience/search',
    invitation: '/itinerary/invitation/:token',
    invitationBeautify: '/itinerary/invitation/',
  },
  auth: {
    index: '/auth',
    login: '/auth/login',
    registerSuccess: '/auth/register/success',
    registerVerificationSuccess: '/auth/registration/verify-email/:token',
    resetPassword: '/auth/reset-password',
    resetPasswordRetry: '/auth/reset-password/retry',
    resetPasswordConfirmPassword: '/auth/password/reset/confirm/:uid/:token',
  },
  discovery: {
    index: '/discovery',
  },
  blog: {
    index: '/blog',
    topic: '/blog/:topic/:category',
    category: '/blog/:category',
    article: '/blog/article/:slug',
  },
  footer: {
    terms: '/terms',
    privacy: '/privacy',
  },
  seo: {
    sitemap: '/sitemap.xml',
  },
  back: -1,
};
