import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { HeaderWithAuth } from '@/components/headers/HeaderWithAuth';
import { SpriteIcon } from '@/components/icons/SpriteIcon';
import {
  ImageContainer,
  convertBlogPostToImageSrcSet,
} from '@/components/containers/ImageContainer';
import { Loader } from '@/components/loaders/Loader';
import { Footer } from '@/components/footer/Footer';
import { LatestArticles } from './LatestArticles';
import { ArticleDescription } from './ArticleDescription';
import { NotFoundPage } from '../NotFoundPage';

import { useGetArticle } from '@/hooks/blog/useGetArticle';
import { useBlogButtonHandlers } from '@/hooks/blog/useBlogButtonHandlers';

import { getFormattedDate } from '@/utils/formatDateTime';
import { DATE_DAY_MONTH_YEAR_FORMAT } from '@/utils/constants';
import { routes } from '@/routes/routes';

import blogPlaceholder from '@assets/images/blog/placeholder.webp';

import './article-styles.css';
import { Helmet } from 'react-helmet';

export const ArticlePage = () => {
  const navigate = useNavigate();
  const { slug: articleSlugFromParams } = useParams();
  const { handleOnArticleClick, handleOnCategoryClick } = useBlogButtonHandlers(
    {},
  );
  const { article, isArticleLoading } = useGetArticle(articleSlugFromParams);

  const handleOnBackClick = () => {
    navigate(routes.blog.index);
  };

  const applyStyle = (html: string | undefined) => {
    if (!html) return '';
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.documentElement.outerHTML;
  };
  const stylizedHtml = applyStyle(article ? article.text : undefined);

  if (isArticleLoading) {
    return <Loader isFullScreen />;
  }

  if (!article && !isArticleLoading) {
    return <NotFoundPage returnToThe={routes.blog.index} />;
  }

  const assertArticle = article as NonNullable<typeof article>;

  return (
    <>
      <Helmet>
        <title>
          {assertArticle.seo_title ??
            'TravelPal Blog - Your Ultimate Travel Guide'}
        </title>
        <meta
          name="description"
          content={
            assertArticle.seo_description ??
            'Explore travel tips, destination guides, and more with TravelPal. Plan your perfect trip today with expert advice.'
          }
        />
        {assertArticle.seo_keywords && (
          <meta name="keywords" content={assertArticle.seo_keywords} />
        )}
        <meta
          property="og:title"
          content={
            assertArticle.seo_title ??
            'TravelPal Blog - Your Ultimate Travel Guide'
          }
        />
        <meta
          property="og:description"
          content={
            assertArticle.seo_description ??
            'Explore travel tips, destination guides, and more with TravelPal. Plan your perfect trip today with expert advice.'
          }
        />
        <meta property="og:image" content="/favicon-32x32.png" />
        <meta
          property="og:url"
          content={`https://travelpal.ai/blog/${assertArticle.slug}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={
            assertArticle.seo_title ??
            'TravelPal Blog - Your Ultimate Travel Guide'
          }
        />
        <meta
          name="twitter:description"
          content={
            assertArticle.seo_description ??
            'Explore travel tips, destination guides, and more with TravelPal. Plan your perfect trip today with expert advice.'
          }
        />
        <meta name="twitter:image" content="/favicon-32x32.png" />
        <link
          rel="canonical"
          href={`https://travelpal.ai/blog/${assertArticle.slug}`}
        />
      </Helmet>
      <div className={classNames('flex flex-col relative w-screen h-screen')}>
        <div className="p-2 mdx:p-7 w-full h-full relative">
          <HeaderWithAuth variant="dark" isAbsolute={false} />
          <div className="my-8 flex items-center gap-x-2 cursor-pointer">
            <SpriteIcon
              iconName="arrowLeft"
              className="w-6 h-6 fill-black"
              onClick={handleOnBackClick}
            />
            <p className="text-p text-[20px] leading-[27px]">Back</p>
          </div>
          <div className="flex flex-col w-full h-full gap-y-6 mdx:gap-y-10">
            <ArticleDescription
              title={assertArticle.title}
              categories={assertArticle.categories ?? []}
              created={getFormattedDate({
                date: new Date(assertArticle.created ?? new Date()),
                format: DATE_DAY_MONTH_YEAR_FORMAT,
              })}
              onCategoryClick={handleOnCategoryClick}
              variant={'large'}
            />
            <div className="relative flex w-full h-full grow flex-col overflow-hidden self-stretch relative flex aspect-[1.5] w-full rounded-t-3xl min-h-[300px] mdx:min-h-[450px] lg:min-h-[650px] max-h-[700px]">
              <ImageContainer
                image={convertBlogPostToImageSrcSet(assertArticle)}
                placeholderImg={blogPlaceholder}
                alt={articleSlugFromParams}
                overrideStylesImage="rounded-32 relative"
                displayImage={true}
              />
            </div>
            <p className="text-p font-[500] leading-[21.6px] opacity-70 text-gray-dark">
              {assertArticle.summary}
            </p>

            {/* Divider */}
            <div className="w-full shrink-0 h-[1px] bg-[#000] opacity-10" />

            {/* Article content */}
            <div
              className={classNames(
                'flex flex-col lg:flex-row justify-between gap-x-10 gap-y-8',
                'w-full max-w-[1315px]',
              )}
            >
              <div
                className="flex flex-col gap-y-8 prose lg:prose-xl container w-full !max-w-full"
                dangerouslySetInnerHTML={{ __html: stylizedHtml }}
              />
              <LatestArticles
                categories={assertArticle.categories ?? []}
                onCategoryClick={handleOnCategoryClick}
                onArticleClick={handleOnArticleClick}
              />
            </div>

            <Footer withoutMargin />
          </div>
        </div>
      </div>
    </>
  );
};
