import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Footer } from '@/components/footer/Footer';
import { HeroBlog } from './HeroBlog';
import { CategorySlider } from './CategorySlider';
import { ArticleSection } from './ArcticleSection';
import { ArticlesList } from './ArticlesList';

import { routes } from '@/routes/routes';
import { topics, useBlogStore } from '@stores/blogStore';

import { useGetCategories } from '@/hooks/blog/useGetCategories';
import { useScrollToTheTop } from '@/hooks/useScrollToTheTop';

import { DEFAULT_BLOG_CATEGORY, DEFAULT_BLOG_TOPIC } from '@/utils/constants';
import { useBlogButtonHandlers } from '@/hooks/blog/useBlogButtonHandlers';
import {
  useGetArticles,
  useGetHeroArticle,
  useGetInfinityArticles,
} from '@/hooks/blog/useGetArticles';
import { BlogPageHelmet } from '@/components/helmets/BlogPageHelmet';

export const BlogPage = () => {
  const navigate = useNavigate();
  const { scrollToItemRef, scrollToTheTop } = useScrollToTheTop();
  const { handleOnCategoryClick, handleOnArticleClick, handleOnViewMore } =
    useBlogButtonHandlers({
      onViewMoreAdditionalMethod: () => {
        scrollToTheTop();
        setPagination();
      },
      onCategoryClickAdditionalMethod: () => {
        scrollToTheTop();
        setPagination();
      },
    });

  const { topic, category } = useParams();
  const {
    activeCategory,
    activeTopic,
    setActiveCategory,
    setActiveTopic,
    setPagination,
    resetStore,
  } = useBlogStore();
  const { categories, isCategoriesLoading } = useGetCategories();

  useEffect(() => {
    if (isCategoriesLoading) return;
    if (!topic || !category) {
      resetStore();
    } else {
      let foundTopic = topics.find((t) => t.slug === topic);
      let foundCategories = categories.find((c) => c.slug === category);
      if (!foundTopic) foundTopic = DEFAULT_BLOG_TOPIC;
      if (!foundCategories) foundCategories = DEFAULT_BLOG_CATEGORY;
      setActiveTopic(foundTopic);
      setActiveCategory(foundCategories);
      navigate(
        routes.blog.topic
          .replace(':topic', foundTopic.slug)
          .replace(':category', foundCategories.slug ?? 'all-categories'),
        { replace: true },
      );
    }
  }, [isCategoriesLoading]);

  const renderStartPage = () => {
    return (
      <div className="flex flex-col gap-y-[100px]">
        {/*        HIGHLIGHTED POSTS       */}
        <ArticleSection
          useDataHook={() =>
            useGetHeroArticle({
              topic: DEFAULT_BLOG_TOPIC.title!!,
              categoryName: DEFAULT_BLOG_CATEGORY.name!!,
              page: 1,
              pageSize: 3,
            })
          }
          onCategoryClick={handleOnCategoryClick}
          onArticleClick={handleOnArticleClick}
          isFirstItemHighlighted={true}
        />
        {/*        TOPICS       */}
        {topics.slice(1).map((topic, index) => (
          <ArticleSection
            key={index}
            topic={topic}
            onViewMore={handleOnViewMore}
            onArticleClick={handleOnArticleClick}
            onCategoryClick={handleOnCategoryClick}
            useDataHook={() =>
              topic.useDataHook!!({
                topic: DEFAULT_BLOG_TOPIC.title!!,
                categoryName: DEFAULT_BLOG_CATEGORY.name!!,
                page: 1,
                pageSize: 3,
              })
            }
          />
        ))}
        {/*        ALL ARTICLES       */}
        <ArticlesList
          header="All Our Blogs"
          onCategoryClick={handleOnCategoryClick}
          onArticleClick={handleOnArticleClick}
          useDataHook={useGetInfinityArticles}
        />
      </div>
    );
  };

  return (
    <>
      <BlogPageHelmet />
      <div className="flex flex-col w-full min-h-screen">
        <HeroBlog activeTopic={activeTopic.title} ref={scrollToItemRef} />
        <div className="mt-[60px] mdx:mt-[72px] px-2 mdx:px-7">
          <CategorySlider
            categories={categories}
            isLoading={isCategoriesLoading}
            activeCategory={activeCategory}
            handleOnCategoryClick={handleOnCategoryClick}
          />
        </div>
        {activeTopic &&
        activeTopic.title === 'Our Blog' &&
        activeCategory &&
        activeCategory.name === 'All Categories' ? (
          renderStartPage()
        ) : (
          <div className="mt-6 mdx:mt-[48px] mb-6 mdx:mb-[78px]">
            <ArticlesList
              topic={activeTopic}
              activeCategory={activeCategory}
              pageSize={11}
              onCategoryClick={handleOnCategoryClick}
              onArticleClick={handleOnArticleClick}
              isWithPagination={true}
              useDataHook={useGetArticles}
            />
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};
