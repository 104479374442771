import usePagination, { UsePaginationProps } from '@mui/material/usePagination';
import classNames from 'classnames';
import { ReactEventHandler } from 'react';

type Props = UsePaginationProps;

type PaginationPageButtonProps = {
  onClick: ReactEventHandler<Element>;
  disabled: boolean;
  selected: boolean;
  page: number;
  textClassNames: string;
};

export const Pagination = ({
  boundaryCount = 0,
  count = 10,
  disabled = false,
  hideNextButton = false,
  hidePrevButton = false,
  showFirstButton = false,
  showLastButton = false,
  onChange,
  page = 1,
  siblingCount = 1,
}: Props) => {
  const { items } = usePagination({
    boundaryCount,
    count,
    disabled,
    hideNextButton,
    hidePrevButton,
    showFirstButton,
    showLastButton,
    onChange,
    page,
    siblingCount,
  });

  const getTextClassNames = (color?: string) =>
    classNames(
      'text-[20px] font-[500] text-center leading-[27px] text-nowrap',
      color ? `text-${color}` : 'text-black',
    );

  const handleOnNextClick = () => {
    if (onChange && count > page) {
      onChange(null as unknown as React.ChangeEvent<unknown>, page + 1);
    }
  };
  if (items.length <= 1) {
    return null;
  }

  return (
    <div className="flex w-full items-center justify-center">
      {items.map((item, index) => {
        if (item.type === 'page' && item.page) {
          return (
            <PaginationPageButton
              key={index}
              onClick={item.onClick}
              disabled={item.disabled}
              selected={item.selected}
              page={item.page}
              textClassNames={getTextClassNames()}
            />
          );
        } else if (item.type === 'end-ellipsis') {
          return (
            <PaginationEllipsis
              key={index}
              textClassNames={getTextClassNames('gray')}
            />
          );
        } else if (item.type === 'next' || item.type === 'previous') {
          return (
            <PaginationNextButton
              key={index}
              textClassNames={getTextClassNames()}
              onClick={handleOnNextClick}
              direction={item.type}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

const ItemContainer = ({
  isButton,
  children,
  ...props
}: {
  isButton: boolean;
  children: React.ReactNode;
  [key: string]: any;
}) => {
  return isButton ? (
    <button className="px-6 py-2" {...props}>
      {children}
    </button>
  ) : (
    <div className="px-6 py-2" {...props}>
      {children}
    </div>
  );
};

const PaginationPageButton = ({
  onClick,
  disabled,
  selected,
  page,
  textClassNames,
}: PaginationPageButtonProps) => {
  return (
    <div className="flex flex-col">
      <ItemContainer isButton onClick={onClick} disabled={disabled}>
        <p className={textClassNames}>{page}</p>
      </ItemContainer>
      <div
        className={classNames('h-[1px] w-full bg-purple', {
          'animate-fadeInReallyFast': selected,
          'animate-fadeOutReallyFast': !selected,
        })}
      />
    </div>
  );
};

const PaginationEllipsis = ({ textClassNames }: { textClassNames: string }) => {
  return (
    <ItemContainer isButton={false} className="px-6 py-2">
      <p className={textClassNames}>...</p>
    </ItemContainer>
  );
};

const PaginationNextButton = ({
  textClassNames,
  onClick,
  direction = 'next',
}: {
  textClassNames: string;
  onClick: () => void;
  direction: 'next' | 'previous';
}) => {
  return (
    <ItemContainer isButton className="px-6 py-2" onClick={onClick}>
      <p className={textClassNames}>
        {direction === 'next' ? 'Next Page' : 'Previous Page'}
      </p>
    </ItemContainer>
  );
};
