import { ItineraryWithExperiences } from '@/types/AppTypes';
import {
  DEFAULT_BLOG_PAGE_CANONICAL_URL,
  DEFAULT_BLOG_PAGE_OG_IMAGE,
  DEFAULT_ITINERARY_PAGE_DESCRIPTION,
  DEFAULT_ITINERARY_PAGE_KEYWORDS,
  DEFAULT_ITINERARY_PAGE_TITLE,
} from '@/utils/constants';
import { Helmet } from 'react-helmet';

export const ItineraryHelmet = ({
  itinerary,
}: {
  itinerary: ItineraryWithExperiences | null | undefined;
}) => {
  const itineraryPageTitle = itinerary
    ? `TravelPal - Itinerary to ${itinerary.city}`
    : DEFAULT_ITINERARY_PAGE_TITLE;
  return (
    <Helmet>
      <title>{itineraryPageTitle}</title>
      <meta name="description" content={DEFAULT_ITINERARY_PAGE_DESCRIPTION} />
      <meta name="keywords" content={DEFAULT_ITINERARY_PAGE_KEYWORDS} />
      <meta property="og:title" content={itineraryPageTitle} />
      <meta
        property="og:description"
        content={DEFAULT_ITINERARY_PAGE_DESCRIPTION}
      />
      <meta property="og:image" content={DEFAULT_BLOG_PAGE_OG_IMAGE} />
      <meta property="og:url" content={DEFAULT_BLOG_PAGE_CANONICAL_URL} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={itineraryPageTitle} />
      <meta
        name="twitter:description"
        content={DEFAULT_ITINERARY_PAGE_DESCRIPTION}
      />
      <meta name="twitter:image" content={DEFAULT_BLOG_PAGE_OG_IMAGE} />
      <link rel="canonical" href={DEFAULT_BLOG_PAGE_CANONICAL_URL} />
    </Helmet>
  );
};
