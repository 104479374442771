import { useNavigate } from 'react-router-dom';
import { useBlogStore } from '@/stores/blogStore';
import { Loader } from '@/components/loaders/Loader';

export const BlogRedirect = () => {
  const navigate = useNavigate();
  const { activeCategory, activeTopic } = useBlogStore();

  navigate(`/blog/${activeTopic.slug}/${activeCategory.slug}`, {
    replace: true,
  });
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <Loader />
    </div>
  );
};
